import React from 'react'
import MaskedInput from 'react-text-mask'

type PhoneInputProps = {
  /** Текст плейсхолдера */
  placeholder: string
  /** классы инпута из  Bootstrap 4 */
  classList: string
  /** Bootstrap 4  брейкпоинт (sm, md, lg, xl)*/
  breakpoint: string
  /** ширина колонки по Bootstrap 4 (от 1 д*/
  column: number
  /** ИД ввода*/
  id: string
  /** имя инпута для Формик */
  name: string
  /**  formik.handleChange */
  onChange?: any
  /** formik.values[name] */
  value?: string
}

const PhoneInput = ({
  placeholder,
  classList,
  breakpoint,
  column,
  id,
  name,
  onChange,
  value,
}: PhoneInputProps) => {
  return (
    <div className={'row'}>
      <div className={`col col-${breakpoint}-${column} mx-auto`}>
        <MaskedInput
          className={classList}
          placeholder={placeholder}
          id={id}
          name={name}
          onChange={onChange}
          value={value}
          mask={[
            '+',

            '7',

            ' ',

            '(',

            /[1-9]/,

            /\d/,

            /\d/,

            ')',

            ' ',

            /\d/,

            /\d/,

            /\d/,

            '-',

            /\d/,

            /\d/,

            '-',

            /\d/,

            /\d/,
          ]}
        />
      </div>
    </div>
  )
}

export default PhoneInput
