import React, { useEffect } from 'react'
import { Redirect, Route } from 'react-router-dom'
import Auth from '../Auth/Auth'

const AnonymousRoute = ({ component, title, ...rest }: any) => {
  const user = Auth()

  useEffect(() => {
    document.title = `${title} - ${process.env.REACT_APP_NAME}`
  })

  return user.authenticated === 'true' ? (
    <Route>
      <Redirect to={{ pathname: '/main' }} />
    </Route>
  ) : (
    <Route {...rest} render={(_) => React.createElement(component, _)} />
  )
}

export default AnonymousRoute
