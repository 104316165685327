import React from 'react'
import { Link } from 'react-router-dom'

type LinkBtnProps = {
  /** Текст внутри кнопки */
  text: string
  /** классы кнопки из  Bootstrap 4 */
  classList: string
  /** Bootstrap 4  брейкпоинт (sm, md, lg, xl)*/
  breakpoint: string
  /** ширина колонки по Bootstrap 4 (от 1 до 12)*/
  column: number
  /** адрес */
  href: string
}

const LinkBtn = ({
  text,
  classList,
  breakpoint,
  column,
  href,
}: LinkBtnProps) => {
  return (
    <div className={`row`}>
      <div className={`col col-${breakpoint}-${column} mx-auto`}>
        <Link to={href}>
          <div className={classList}>{text}</div>
        </Link>
      </div>
    </div>
  )
}

export default LinkBtn
