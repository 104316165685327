import React from 'react'

type InputProps = {
  /** Текст плейсхолдера */
  placeholder: string
  /** Тип поля ввода */
  inputType: string
  /** классы инпута из  Bootstrap 4 */
  classList: string
  /** Bootstrap 4  брейкпоинт (sm, md, lg, xl)*/
  breakpoint: string
  /** ширина колонки по Bootstrap 4 (от 1 д*/
  column: number
  /** ИД ввода*/
  id: string
  /** имя инпута для Формик */
  name: string

  /**TODO
   * remove optional (question mark)
   */

  /**  formik.handleChange */
  onChange?: any
  /** formik.values[name] */
  value?: string
}

const Input = ({
  placeholder,
  inputType,
  classList,
  breakpoint,
  column,
  id,
  name,
  onChange,
  value,
}: InputProps) => {
  return (
    <div className={'row'}>
      <div className={`col col-${breakpoint}-${column} mx-auto`}>
        <input
          type={inputType}
          className={classList}
          placeholder={placeholder}
          id={id}
          name={name}
          onChange={onChange}
          value={value}
        />
      </div>
    </div>
  )
}

export default Input
