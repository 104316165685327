import React, { useState } from 'react'
import Input from '../../Atoms/Input/Input'
import PhoneInput from '../../Atoms/PhoneInput/PhoneInput'
import InputBtn from '../../Atoms/InputBtn/Button'
import { useFormik } from 'formik'
import { AxiosResponse } from 'axios'
import 'react-toastify/dist/ReactToastify.css'
import { useHistory } from 'react-router-dom'
import Axios from 'axios'
import './login.scss'

const Login = () => {
  /** хук для контроля состояния полей ввода */
  const [disabled, setDisabled] = useState(false)
  const history = useHistory()

  const formik = useFormik({
    initialValues: {
      phone: '',
      password: '',
    },
    onSubmit: async (values) => {
      /**
       * Получает токен аутентификации
       * @param values
       */
      await Axios.post('/api/v1/tokens', values)
        .then((response: AxiosResponse) => {
          /** отключает поля ввода до получения ответа от сервера */
          setDisabled(true)

          /** записывает в localStorage данные ответа от сервера */
          localStorage.setItem('authenticated', 'true')
          localStorage.setItem('tokenJwt', response.data.jwt)
          localStorage.setItem('refreshTokenId', response.data.refreshTokenId)

          history.replace('/main')
        })
        .finally(() => {
          /** включает поля ввода */
          setDisabled(false)
        })
    },
  })

  return (
    <div className="container-fluid login">
      <div className="row">
        <div className="col text-center mb-2">
          <h3>Личный кабинет водителя</h3>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <form onSubmit={formik.handleSubmit}>
            <fieldset id={'loginFieldset'} disabled={disabled}>
              <label className="sr-only">
                Форма входа в учётную запись
                <input type="text" />
              </label>
              <PhoneInput
                placeholder={'Логин'}
                classList={'form-control mb-3'}
                breakpoint={'md'}
                column={12}
                id={'phone'}
                name={'phone'}
                onChange={formik.handleChange}
                value={formik.values.phone}
              />

              <Input
                placeholder={'Пароль'}
                inputType={'password'}
                classList={'form-control mb-4'}
                breakpoint={'md'}
                column={12}
                id={'password'}
                name={'password'}
                onChange={formik.handleChange}
                value={formik.values.password}
              />

              <InputBtn
                text={'Войти'}
                classList={'btn btn-block btn-lg btn-success'}
                breakpoint={'md'}
                column={12}
              />
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Login
