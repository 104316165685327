import AsyncSelect from 'react-select/async'
import { FormEvent, useEffect, useState } from 'react'
import StandardHeader from '../../Molecules/StandardHeader/StandardHeader'
import Button from '../../Atoms/Button/Button'
import DatePicker from 'react-datepicker'
import Axios from 'axios'
import { Link, useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { toStringWithoutTimezone } from '../../toStringWithoutTimezone'
import { ruParseFloat } from '../../Api/document/ruParseFloat'
import { toNumber } from '../../Api/document/toNumber'
import { ButtonDatePicker } from '../../Api/document/buttonDatePicker'
import { toDateFromUtc } from '../../toDateFromUtc'

export const EditSupplyHistory = () => {
  const [dateTimeFrom, setDateTimeFrom] = useState(new Date())
  const [contractorIdFrom, setContractorIdFrom] = useState('')
  const [contractorNameFrom, setContractorNameFrom] = useState('')
  const [contractorObjectIdFrom, setContractorObjectIdFrom] = useState('')
  const [contractorObjectId, setContractorObjectId] = useState('')
  const [contractorObjectNameFrom, setContractorObjectNameFrom] = useState('')
  const [
    contractorObjectAddressFrom,
    setContractorObjectAddressFrom,
  ] = useState('')
  const [contractorObjectAddressTo, setContractorObjectAddressTo] = useState('')
  const [contractorNameTo, setContractorNameTo] = useState<string>()
  const [contractorIdTo, setContractorIdTo] = useState('')
  const [contractorObjectNameTo, setContractorObjectNameTo] = useState<string>()
  const [contractorObjectIdTo, setContractorObjectIdTo] = useState('')
  const [
    contractorMaterialObjectId,
    setContractorMaterialObjectId,
  ] = useState<string>()
  const [volumeFrom, setVolumeFrom] = useState(0)
  const [volumeTypeFrom, setVolumeTypeFrom] = useState(0)
  const [idleFrom, setIdleFrom] = useState(false)
  const [idleEndDateTimeFrom, setIdleEndDateTimeFrom] = useState<Date>()
  const [idleStartDateTimeFrom, setIdleStartDateTimeFrom] = useState<Date>()
  const [idleCommentFrom, setIdleCommentFrom] = useState<string>()
  const [documentFrom, setDocumentFrom] = useState<Blob>()
  const [documentTo, setDocumentTo] = useState<Blob>()
  const [dateTimeTo, setDateTimeTo] = useState<Date>()
  const [disabled, setDisabled] = useState(false)
  const [idleTo, setIdleTo] = useState(false)
  const [idleStartDateTimeTo, setIdleStartDateTimeTo] = useState<Date>()
  const [idleEndDateTimeTo, setIdleEndDateTimeTo] = useState<Date>()
  const [idleCommentTo, setIdleCommentTo] = useState<string>()
  const [
    contractorMaterialObjectMaterialName,
    setContractorMaterialObjectMaterialName,
  ] = useState('')
  const [volumeTo, setVolumeTo] = useState<number>()
  const [volumeTypeTo, setVolumeTypeTo] = useState(0)
  const history = useHistory()
  const { tripId } = useParams<{ tripId: string }>()
  const [waybillNumber, setWaybillNumber] = useState<string>()
  const [invoiceNumber, setInvoiceNumber] = useState<string>()
  const [companyId, setCompanyId] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [ticketNumber, setTicketNumber] = useState('')

  useEffect(() => {
    const loadTrip = async () => {
      setDisabled(true)

      try {
        const trip = await Axios.get<{
          type: number
          marked: boolean

          dateTimeFrom: string

          contractorNameFrom: string
          contractorInnFrom: string
          contractorIdFrom: string

          contractorObjectNameFrom: string
          contractorObjectAddressFrom: string
          contractorObjectIdFrom: string

          idleFrom: boolean
          idleStartDateTimeFrom: string
          idleEndDateTimeFrom: string
          idleCommentFrom: string

          dateTimeTo: string

          contractorNameTo: string
          contractorInnTo: string
          contractorIdTo: string

          contractorObjectNameTo: string
          contractorObjectAddressTo: string
          contractorObjectIdTo: string

          idleTo: boolean
          idleStartDateTimeTo: string
          idleEndDateTimeTo: string
          idleCommentTo: string

          materialName: string
          contractorMaterialObjectId: string

          volumeFrom: number
          volumeTypeFrom?: number
          volumeTo: number
          volumeTypeTo?: number
          payment: number
          invoiceNumber: string
          waybillNumber: string

          completed: boolean

          companyId: string
          companyName: string

          ticketNumber: string
        }>(`/api/v1/trips/${tripId}`)

        setDateTimeFrom(toDateFromUtc(trip.data.dateTimeFrom))

        setContractorNameFrom(trip.data.contractorNameFrom)
        setContractorIdFrom(trip.data.contractorIdFrom)

        setContractorObjectNameFrom(trip.data.contractorObjectNameFrom)
        setContractorObjectIdFrom(trip.data.contractorObjectIdFrom)
        setContractorObjectId(trip.data.contractorObjectIdFrom)

        setIdleFrom(trip.data.idleFrom)
        setIdleStartDateTimeFrom(toDateFromUtc(trip.data.idleStartDateTimeFrom))
        setIdleEndDateTimeFrom(toDateFromUtc(trip.data.idleEndDateTimeFrom))
        setIdleCommentFrom(trip.data.idleCommentFrom)

        setContractorObjectAddressFrom(trip.data.contractorObjectAddressFrom)
        setContractorObjectAddressTo(trip.data.contractorObjectAddressTo)

        if (trip.data.dateTimeTo) {
          setDateTimeTo(toDateFromUtc(trip.data.dateTimeTo))
        }

        if (trip.data.contractorIdTo) {
          setContractorIdTo(trip.data.contractorIdTo)
          setContractorNameTo(trip.data.contractorNameTo)
          setContractorObjectNameTo(trip.data.contractorObjectNameTo)
          setContractorObjectIdTo(trip.data.contractorObjectIdTo)
        }
        setIdleTo(trip.data.idleTo)
        setIdleStartDateTimeTo(toDateFromUtc(trip.data.idleStartDateTimeTo))
        setIdleEndDateTimeTo(toDateFromUtc(trip.data.idleEndDateTimeTo))
        setIdleCommentTo(trip.data.idleCommentTo)

        if (trip.data.contractorMaterialObjectId) {
          setContractorMaterialObjectMaterialName(trip.data.materialName)
          setContractorMaterialObjectId(trip.data.contractorMaterialObjectId)
        }

        setVolumeFrom(trip.data.volumeFrom)
        setVolumeTypeFrom(trip.data.volumeTypeFrom || 0)
        setVolumeTo(trip.data.volumeTo)
        setVolumeTypeTo(trip.data.volumeTypeTo || 0)
        setInvoiceNumber(trip.data.invoiceNumber)
        setWaybillNumber(trip.data.waybillNumber)

        if (trip.data.companyId) {
          setCompanyId(trip.data.companyId)
          setCompanyName(trip.data.companyName)
        }

        setTicketNumber(trip.data.ticketNumber)
      } finally {
        setDisabled(false)
      }
    }

    loadTrip()
  }, [tripId])

  const editTrip = async (_: FormEvent) => {
    _.preventDefault()

    setDisabled(true)

    try {
      if (dateTimeTo && new Date(dateTimeFrom) > new Date(dateTimeTo)) {
        alert('Дата разгрузки должна быть больше или равна дате погрузки.')
        return
      }

      const formData = new FormData()

      formData.append('type', '0')
      formData.append('dateTimeFrom', toStringWithoutTimezone(dateTimeFrom))
      formData.append('contractorIdFrom', contractorIdFrom)
      formData.append('contractorObjectIdFrom', contractorObjectIdFrom)
      formData.append('contractorObjectId', contractorObjectId)
      formData.append('contractorIdTo', contractorIdTo)
      formData.append('contractorObjectIdTo', contractorObjectIdTo)
      formData.append(
        'contractorMaterialObjectId',
        contractorMaterialObjectId || ''
      )
      formData.append('volumeFrom', volumeFrom.toString())
      formData.append('volumeTypeFrom', volumeTypeFrom.toString())
      formData.append('idleFrom', idleFrom.toString())
      formData.append(
        'idleStartDateTimeFrom',
        toStringWithoutTimezone(idleStartDateTimeFrom)
      )
      formData.append(
        'idleEndDateTimeFrom',
        toStringWithoutTimezone(idleEndDateTimeFrom)
      )
      formData.append('idleCommentFrom', idleCommentFrom || '')
      formData.append('documentFrom', documentFrom || '')
      formData.append('documentTo', documentTo || '')
      formData.append('dateTimeTo', toStringWithoutTimezone(dateTimeTo))
      formData.append('idleTo', idleTo.toString())
      formData.append(
        'idleStartDateTimeTo',
        toStringWithoutTimezone(idleStartDateTimeTo)
      )
      formData.append(
        'idleEndDateTimeTo',
        toStringWithoutTimezone(idleEndDateTimeTo)
      )
      formData.append('idleCommentTo', idleCommentTo || '')
      formData.append('volumeTo', volumeTo?.toString() || '')
      formData.append('volumeTypeTo', volumeTypeTo.toString())
      formData.append('invoiceNumber', invoiceNumber || '')
      formData.append('waybillNumber', waybillNumber || '')
      formData.append('companyId', companyId || '')
      formData.append('ticketNumber', ticketNumber || '')

      await Axios.put<{ data: string }>(`/api/v1/trips/${tripId}`, formData)

      toast.success('Поездка обновлена.')

      history.push('/main')
    } finally {
      setDisabled(false)
    }
  }

  return (
    <>
      <StandardHeader
        iconClass={'bi bi-journal-arrow-down'}
        title={'Поставка'}
      />
      <form onSubmit={editTrip}>
        <fieldset disabled={disabled}>
          <div className="container-fluid mt-3">
            <div className="form-group">
              <label>Начало погрузки</label>
              <DatePicker
                required={true}
                locale="ru"
                className="form-control"
                selected={dateTimeFrom}
                onChange={(date: Date) => setDateTimeFrom(date)}
                dateFormat="dd.MM.yyyy HH:mm"
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={30}
                timeCaption="Время"
                isClearable
                popperPlacement="bottom"
                maxDate={new Date()}
                customInput={<ButtonDatePicker />}
              />
            </div>

            <div className="h6 font-weight-bold">Точка погрузки</div>
            <div className="form-group">
              <label>Адрес</label>
              <AsyncSelect
                cacheOptions
                defaultOptions
                loadOptions={(inputValue: string, callback: any) => {
                  const loadContractorsObjects = async (
                    inputValue: string,
                    callback: any
                  ) => {
                    const contractorsObjects = await Axios.get<{
                      items: {
                        id: string
                        name: string
                        address: string
                        contractorName: string
                        contractorId: string
                      }[]
                    }>(`/api/v1/contractors-objects?query=${inputValue}&page=1`)

                    callback(
                      contractorsObjects.data.items.map((_) => {
                        return {
                          value: _.id,
                          label: `${_.name}-${_.address}-${_.contractorName}`,
                          contractorId: _.contractorId,
                          name: _.name,
                          address: _.address,
                          contractorName: _.contractorName,
                        }
                      })
                    )
                  }

                  loadContractorsObjects(inputValue, callback)
                }}
                onChange={(_: any) => {
                  setContractorObjectId(_.value)
                  setContractorObjectIdFrom(_.value)
                  setContractorObjectNameFrom(_.name)
                  setContractorObjectAddressFrom(_.address)
                  setContractorIdFrom(_.contractorId)
                  setContractorNameFrom(_.contractorName)
                }}
                value={{
                  value: contractorObjectIdFrom,
                  label: `${contractorObjectNameFrom}-${contractorObjectAddressFrom}-${contractorNameFrom}`,
                  contractorId: contractorIdFrom,
                }}
              />
              <input
                type="text"
                value={contractorObjectIdFrom ? '-' : ''}
                style={{ height: 0, opacity: 0 }}
                required
              />
            </div>
            <div
              className="form-group"
              hidden={!contractorObjectIdFrom}
              style={{ marginTop: -24 }}
            >
              <label>Наименование контрагента</label>
              <AsyncSelect
                key={contractorObjectIdFrom}
                cacheOptions
                defaultOptions
                loadOptions={(inputValue: string, callback: any) => {
                  const loadContractors = async (
                    inputValue: string,
                    callback: any
                  ) => {
                    const contractors = await Axios.get<{
                      items: {
                        id: string
                        name: string
                        inn: string
                      }[]
                    }>(
                      `/api/v1/contractors?query=${inputValue}&contractorObjectId=${
                        contractorObjectIdFrom || ''
                      }&page=1`
                    )

                    callback(
                      contractors.data.items.map((_) => {
                        return {
                          value: _.id,
                          label: `${_.name}`,
                        }
                      })
                    )
                  }

                  loadContractors(inputValue, callback)
                }}
                onChange={(_: any) => {
                  setContractorIdFrom(_.value)
                  setContractorNameFrom(_.label)
                }}
                value={{
                  value: contractorIdFrom,
                  label: contractorNameFrom,
                }}
              />
              <input
                type="text"
                value={contractorIdFrom ? '-' : ''}
                style={{ height: 0, opacity: 0 }}
                required
              />
            </div>

            <div className="h6 font-weight-bold">Точка разгрузки</div>
            <div className="form-group">
              <label>Адрес</label>
              <AsyncSelect
                cacheOptions
                defaultOptions
                loadOptions={(inputValue: string, callback: any) => {
                  const loadContractorsObjects = async (
                    inputValue: string,
                    callback: any
                  ) => {
                    const contractorsObjects = await Axios.get<{
                      items: {
                        id: string
                        name: string
                        address: string
                        contractorName: string
                        contractorId: string
                      }[]
                    }>(`/api/v1/contractors-objects?query=${inputValue}&page=1`)

                    callback(
                      contractorsObjects.data.items.map((_) => {
                        return {
                          value: _.id,
                          label: `${_.name}-${_.address}-${_.contractorName}`,
                          contractorId: _.contractorId,
                          name: _.name,
                          address: _.address,
                          contractorName: _.contractorName,
                        }
                      })
                    )
                  }

                  loadContractorsObjects(inputValue, callback)
                }}
                onChange={(_: any) => {
                  setContractorObjectIdTo(_.value)
                  setContractorObjectNameTo(_.name)
                  setContractorObjectAddressTo(_.address)
                  setContractorIdTo(_.contractorId)
                  setContractorNameTo(_.contractorName)
                }}
                value={{
                  value: contractorObjectIdTo,
                  label: `${contractorObjectNameTo}-${contractorObjectAddressTo}-${contractorNameTo}`,
                  contractorId: contractorIdTo,
                }}
              />
              <input
                type="text"
                value={contractorObjectIdTo ? '-' : ''}
                style={{ height: 0, opacity: 0 }}
                required
              />
            </div>
            <div
              className="form-group"
              hidden={!contractorObjectIdTo}
              style={{ marginTop: -24 }}
            >
              <label>Наименование контрагента</label>
              <AsyncSelect
                key={contractorObjectIdTo}
                cacheOptions
                defaultOptions
                loadOptions={(inputValue: string, callback: any) => {
                  const loadContractors = async (
                    inputValue: string,
                    callback: any
                  ) => {
                    const contractors = await Axios.get<{
                      items: {
                        id: string
                        name: string
                        inn: string
                      }[]
                    }>(
                      `/api/v1/contractors?query=${inputValue}&contractorObjectId=${
                        contractorObjectIdTo || ''
                      }&page=1`
                    )

                    callback(
                      contractors.data.items.map((_) => {
                        return {
                          value: _.id,
                          label: `${_.name}`,
                        }
                      })
                    )
                  }

                  loadContractors(inputValue, callback)
                }}
                onChange={(_: any) => {
                  setContractorIdTo(_.value)
                  setContractorNameTo(_.label)
                }}
                value={{
                  value: contractorIdTo,
                  label: contractorNameTo,
                }}
              />
              <input
                type="text"
                value={contractorIdTo ? '-' : ''}
                style={{ height: 0, opacity: 0, display: 'none' }}
                required
              />
            </div>

            <div className="h6 font-weight-bold">Сведения о загрузке</div>
            <div className="form-group">
              <label>Материал</label>
              <AsyncSelect
                key={contractorObjectIdTo}
                isDisabled={!contractorObjectIdTo}
                cacheOptions
                defaultOptions
                loadOptions={(inputValue: string, callback: any) => {
                  const loadMaterials = async (
                    inputValue: string,
                    callback: any
                  ) => {
                    const materials = await Axios.get<{
                      items: {
                        id: string
                        materialName: string
                      }[]
                    }>(
                      `/api/v1/contractors-materials-objects?query=${inputValue}&contractorObjectId=${contractorObjectIdTo}&page=1`
                    )

                    callback(
                      materials.data.items.map((_) => {
                        return {
                          value: _.id,
                          label: _.materialName,
                        }
                      })
                    )
                  }

                  loadMaterials(inputValue, callback)
                }}
                onChange={(_: any) => {
                  if (_) {
                    setContractorMaterialObjectId(_.value)
                    setContractorMaterialObjectMaterialName(_.label)
                    return
                  }

                  setContractorMaterialObjectId(undefined)
                  setContractorMaterialObjectMaterialName('')
                }}
                value={{
                  value: contractorMaterialObjectId,
                  label: contractorMaterialObjectMaterialName,
                }}
              />
              <input
                type="text"
                value={contractorMaterialObjectId ? '-' : ''}
                style={{ height: 0, opacity: 0 }}
                required
              />
            </div>

            <div className="form-row" style={{ marginTop: -24 }}>
              <div className="col">
                <label>Объём</label>
                <input
                  type="text"
                  className="form-control"
                  defaultValue={volumeFrom}
                  required
                  onChange={(_) => {
                    _.target.value = toNumber(_.target.value)

                    const floatNumber = ruParseFloat(_.target.value)

                    if (
                      (volumeTypeFrom === 0 && floatNumber > 60) ||
                      (volumeTypeFrom === 1 && floatNumber > 60)
                    ) {
                      alert('Не более 60 тонн или 60 М³.')
                    }

                    setVolumeFrom(floatNumber)
                  }}
                  value={volumeFrom}
                />
              </div>
              <div className="col">
                <label>Единица измерения</label>
                <select
                  required
                  className="form-control"
                  value={volumeTypeFrom}
                  onChange={(_) => {
                    const type = parseInt(_.target.value)

                    if (
                      (type === 0 && (volumeFrom || 0) > 65) ||
                      (type === 1 && (volumeFrom || 0) > 40)
                    ) {
                      alert('Не более 65 тонн или 40 М³.')
                    }

                    setVolumeTypeFrom(type)
                  }}
                >
                  <option value={0}>Тонны</option>
                  <option value={1}>М³</option>
                </select>
              </div>
            </div>

            <div className="form-group form-check mt-3">
              <input
                className="form-check-input"
                type="checkbox"
                checked={idleFrom}
                onChange={(_) => {
                  setIdleFrom(!idleFrom)
                }}
                id="idleFrom"
              />
              <label className="form-check-label" htmlFor="idleFrom">
                <strong>Простой на объекте</strong>
              </label>
            </div>

            {idleFrom ? (
              <>
                <div className="form-group">
                  <label>С</label>
                  <DatePicker
                    required={true}
                    locale="ru"
                    className="form-control"
                    selected={idleStartDateTimeFrom}
                    onChange={(date: Date) => setIdleStartDateTimeFrom(date)}
                    dateFormat="dd.MM.yyyy HH:mm"
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={30}
                    timeCaption="Время"
                    isClearable
                    popperPlacement="bottom"
                    maxDate={new Date()}
                    customInput={<ButtonDatePicker />}
                  />
                </div>
                <div className="form-group">
                  <label>По</label>
                  <DatePicker
                    required={true}
                    locale="ru"
                    className="form-control"
                    selected={idleEndDateTimeFrom}
                    onChange={(date: Date) => setIdleEndDateTimeFrom(date)}
                    dateFormat="dd.MM.yyyy HH:mm"
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={30}
                    timeCaption="Время"
                    isClearable
                    minDate={idleStartDateTimeFrom}
                    popperPlacement="bottom"
                    maxDate={new Date()}
                    customInput={<ButtonDatePicker />}
                  />
                </div>
                <div className="form-group">
                  <label>Комментарий при простое</label>
                  <textarea
                    className="form-control"
                    required
                    rows={5}
                    value={idleCommentFrom}
                    onChange={(_) => setIdleCommentFrom(_.target.value)}
                  ></textarea>
                </div>
              </>
            ) : null}

            <div className="form-group">
              <label>Фото накладной</label>
              <input
                className="form-control"
                type="file"
                accept="image/x-png,image/gif,image/jpeg"
                onChange={(_) => {
                  if (!_.target.files) {
                    return
                  }

                  if (
                    _.target.files[0].size >
                    process.env.REACT_APP_UPLOAD_SIZE_LIMIT
                  ) {
                    alert(
                      `Выберите файл размером меньше ${
                        process.env.REACT_APP_UPLOAD_SIZE_LIMIT / 1000 / 1000
                      }МБ.`
                    )

                    return
                  }

                  setDocumentFrom(_.target.files[0])
                }}
              />
            </div>

            <hr />

            <div className="row">
              <div className="col">
                <strong>Сведения о разгрузке</strong>
                <br />
                <div className="form-group">
                  <label>Сведения о разгрузке</label>
                  <DatePicker
                    required={true}
                    locale="ru"
                    className="form-control"
                    selected={dateTimeTo}
                    onChange={(date: Date) => setDateTimeTo(date)}
                    dateFormat="dd.MM.yyyy HH:mm"
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={30}
                    timeCaption="Время"
                    isClearable
                    minDate={dateTimeFrom}
                    maxDate={new Date()}
                    popperPlacement="bottom"
                    customInput={<ButtonDatePicker />}
                  />
                </div>

                <div className="form-row">
                  <div className="col">
                    <label>Объём</label>
                    <input
                      type="text"
                      className="form-control"
                      defaultValue={volumeTo}
                      required
                      onChange={(_) => {
                        _.target.value = toNumber(_.target.value)

                        const floatNumber = ruParseFloat(_.target.value)

                        if (
                          (volumeTypeTo === 0 && floatNumber > 60) ||
                          (volumeTypeTo === 1 && floatNumber > 60)
                        ) {
                          alert('Не более 60 тонн или 60 М³.')
                        }

                        setVolumeTo(floatNumber)
                      }}
                    />
                  </div>
                  <div className="col">
                    <label>Единица измерения</label>
                    <select
                      required
                      className="form-control"
                      value={volumeTypeTo}
                      onChange={(_) => {
                        const type = parseInt(_.target.value)

                        if (
                          (type === 0 && (volumeTo || 0) > 60) ||
                          (type === 1 && (volumeTo || 0) > 60)
                        ) {
                          alert('Не более 60 тонн или 60 М³.')
                        }

                        setVolumeTypeTo(type)
                      }}
                    >
                      <option value={0}>Тонны</option>
                      <option value={1}>М³</option>
                    </select>
                  </div>
                </div>

                <div className="form-group form-check mt-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={idleTo}
                    onChange={(_) => {
                      setIdleTo(!idleTo)
                    }}
                    id="idleTo"
                  />
                  <label className="form-check-label" htmlFor="idleTo">
                    <strong>Простой на объекте</strong>
                  </label>
                </div>

                {idleTo ? (
                  <>
                    <div className="form-group">
                      <label>С</label>
                      <DatePicker
                        required={true}
                        locale="ru"
                        className="form-control"
                        selected={idleStartDateTimeTo}
                        onChange={(date: Date) => setIdleStartDateTimeTo(date)}
                        dateFormat="dd.MM.yyyy HH:mm"
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={30}
                        timeCaption="Время"
                        isClearable
                        popperPlacement="bottom"
                        maxDate={new Date()}
                        customInput={<ButtonDatePicker />}
                      />
                    </div>
                    <div className="form-group">
                      <label>По</label>
                      <DatePicker
                        required={true}
                        locale="ru"
                        className="form-control"
                        selected={idleEndDateTimeTo}
                        onChange={(date: Date) => setIdleEndDateTimeTo(date)}
                        dateFormat="dd.MM.yyyy HH:mm"
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={30}
                        timeCaption="Время"
                        isClearable
                        minDate={idleStartDateTimeTo}
                        popperPlacement="bottom"
                        maxDate={new Date()}
                        customInput={<ButtonDatePicker />}
                      />
                    </div>
                    <div className="form-group">
                      <label>Комментарий при простое</label>
                      <textarea
                        className="form-control"
                        required
                        rows={5}
                        value={idleCommentTo}
                        onChange={(_) => setIdleCommentTo(_.target.value)}
                      ></textarea>
                    </div>
                  </>
                ) : null}

                <div className="form-group">
                  <label>Номер путевого листа</label>
                  <input
                    type="text"
                    className="form-control"
                    value={waybillNumber}
                    onChange={(_) => setWaybillNumber(_.target.value)}
                  />
                </div>

                <div className="form-group">
                  <label>Номер накладной</label>
                  <input
                    type="text"
                    className="form-control"
                    value={invoiceNumber}
                    onChange={(_) => setInvoiceNumber(_.target.value)}
                    required
                  />
                </div>

                <div className="form-group">
                  <label>Фото накладной</label>
                  <input
                    className="form-control"
                    type="file"
                    accept="image/x-png,image/gif,image/jpeg"
                    onChange={(_) => {
                      if (!_.target.files) {
                        return
                      }

                      if (
                        _.target.files[0].size >
                        process.env.REACT_APP_UPLOAD_SIZE_LIMIT
                      ) {
                        alert(
                          `Выберите файл размером меньше ${
                            process.env.REACT_APP_UPLOAD_SIZE_LIMIT /
                            1000 /
                            1000
                          }МБ.`
                        )

                        return
                      }

                      setDocumentTo(_.target.files[0])
                    }}
                  />
                </div>

                <div className="h6 font-weight-bold">Организация</div>
                <div className="form-group">
                  <label>Организация</label>
                  <AsyncSelect
                    defaultOptions
                    isClearable={true}
                    value={{
                      value: companyId,
                      label: companyName,
                    }}
                    loadOptions={(inputValue: string, callback: any) => {
                      const loadCompanies = async (
                        inputValue: string,
                        callback: any
                      ) => {
                        const companies = await Axios.get<{
                          items: {
                            id: string
                            name: string
                          }[]
                        }>(`/api/v1/companies?query=${inputValue}&page=1`)

                        callback(
                          companies.data.items.map((_) => {
                            return {
                              value: _.id,
                              label: _.name,
                            }
                          })
                        )
                      }

                      loadCompanies(inputValue, callback)
                    }}
                    onChange={(_: any) => {
                      if (_) {
                        setCompanyId(_.value)
                        setCompanyName(_.label)
                        return
                      }

                      setCompanyId('')
                      setCompanyName('')
                    }}
                  />
                  <input
                    type="text"
                    value={contractorObjectIdFrom ? '-' : ''}
                    style={{ height: 0, opacity: 0 }}
                    required
                  />
                </div>

                <div className="h6 font-weight-bold">Номер талона</div>
                <div className="form-group">
                  <label>Номер</label>
                  <input
                    type="text"
                    className="form-control"
                    value={ticketNumber}
                    onChange={(_) => setTicketNumber(_.target.value || '')}
                  />
                </div>

                <div className="form-group">
                  <Button
                    text={'Сохранить'}
                    classList={'btn btn-block btn-lg btn-success'}
                    name={'supplyForm'}
                    breakpoint={'md'}
                    column={12}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="form-group">
                  <Link
                    to="/history"
                    className="btn btn-block btn-outline-secondary"
                  >
                    К истории заказов
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </form>
    </>
  )
}
