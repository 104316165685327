import React from 'react'

type ProfileHeaderProps = {
  /** путь к изображению профиля пользлвателя */
  imageUrl: string | undefined
  /** ФИО пользователя */
  fullName: string | undefined
  /** ГОС номер назначенного транспортного средства  ИЛИ уведомление об отсуствии такогого */
  licensePlate: string | undefined
}
/**
 * Компонент Шапка Профиля.
 * ПРИМЕЧАНИЯ:
 * - фото пользователя должно быть строго квадратным, иначе обрезка в кгруг происходить не будет
 * - если фото не загружено, следует передавать undefined для отображения стандартной аватарки
 * - ФИО следует передавать полностью с заглавных букв (Иванов Иван Иванович)
 * - ГОС номер ергистрации можно передавать в любом регистре, стиль переводит в  UPPERCASE
 *
 * кастомные стили использованы для:
 * -- контроля размера изображения (аватара)
 * -- фиксированной высоты навбара
 * -- тонкой настройки текста
 *
 */
const ProfileHeader = ({
  imageUrl,
  fullName,
  licensePlate,
}: ProfileHeaderProps) => {
  return (
    <div className="navbar sticky-top shadow bg-white">
      <div className="container-fluid">
        <div className="navbar-brand">
          {fullName}
          <br />
          {!licensePlate ? (
            <span className="text-danger">Автомобиль не назначен</span>
          ) : (
            <span className="text-uppercase">{licensePlate}</span>
          )}
        </div>
      </div>
    </div>
  )
}

export default ProfileHeader
