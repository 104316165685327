import React, { FormEvent, useEffect, useState } from 'react'
import StandardHeader from '../../Molecules/StandardHeader/StandardHeader'
import Button from '../../Atoms/Button/Button'
import DatePicker from 'react-datepicker'
import Axios from 'axios'
import { Link, useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { format } from 'date-fns'
import { downloadFile } from '../../Api/document/downloadFile'
import queryString from 'query-string'
import { toStringWithoutTimezone } from '../../toStringWithoutTimezone'
import { ruParseFloat } from '../../Api/document/ruParseFloat'
import { toNumber } from '../../Api/document/toNumber'
import { ButtonDatePicker } from '../../Api/document/buttonDatePicker'
import NumberFormat from 'react-number-format'
import { toDateFromUtc } from '../../toDateFromUtc'

export const EditSupply = () => {
  const [dateTimeFrom, setDateTimeFrom] = useState(new Date())
  const [contractorIdFrom, setContractorIdFrom] = useState('')
  const [contractorNameFrom, setContractorNameFrom] = useState('')
  const [contractorObjectIdFrom, setContractorObjectIdFrom] = useState('')
  const [contractorObjectId, setContractorObjectId] = useState('')
  const [contractorObjectNameFrom, setContractorObjectNameFrom] = useState('')
  const [contractorNameTo, setContractorNameTo] = useState<string>()
  const [contractorIdTo, setContractorIdTo] = useState('')
  const [contractorObjectNameTo, setContractorObjectNameTo] = useState<string>()
  const [contractorObjectIdTo, setContractorObjectIdTo] = useState('')
  const [
    contractorMaterialObjectId,
    setContractorMaterialObjectId,
  ] = useState<string>()
  const [volumeFrom, setVolumeFrom] = useState(0)
  const [volumeTypeFrom, setVolumeTypeFrom] = useState(0)
  const [idleFrom, setIdleFrom] = useState(false)
  const [idleEndDateTimeFrom, setIdleEndDateTimeFrom] = useState<Date>()
  const [idleStartDateTimeFrom, setIdleStartDateTimeFrom] = useState<Date>()
  const [idleCommentFrom, setIdleCommentFrom] = useState<string>()
  const [documentFrom, setDocumentFrom] = useState<Blob>()
  const [documentTo, setDocumentTo] = useState<Blob>()
  const [dateTimeTo, setDateTimeTo] = useState<Date>()
  const [disabled, setDisabled] = useState(false)
  const [idleTo, setIdleTo] = useState(false)
  const [idleStartDateTimeTo, setIdleStartDateTimeTo] = useState<Date>()
  const [idleEndDateTimeTo, setIdleEndDateTimeTo] = useState<Date>()
  const [idleCommentTo, setIdleCommentTo] = useState<string>()
  const [
    contractorMaterialObjectMaterialName,
    setContractorMaterialObjectMaterialName,
  ] = useState('')
  const [volumeTo, setVolumeTo] = useState<number>()
  const [volumeTypeTo, setVolumeTypeTo] = useState(0)
  const history = useHistory()
  const { tripId } = useParams<{ tripId: string }>()
  const [documents, setDocuments] = useState<
    { id: string; type: string; name: string }[]
  >()
  const [toHidden, setToHidden] = useState(false)
  const [payment, setPayment] = useState(0)
  const [waybillNumber, setWaybillNumber] = useState<string>()
  const [invoiceNumber, setInvoiceNumber] = useState<string>()
  const [companyId, setCompanyId] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [ticketNumber, setTicketNumber] = useState('')

  const queryParams = queryString.parse(window.location.search)

  useEffect(() => {
    const loadTrip = async () => {
      setDisabled(true)

      try {
        const trip = await Axios.get<{
          type: number
          marked: boolean

          dateTimeFrom: string

          contractorNameFrom: string
          contractorInnFrom: string
          contractorIdFrom: string

          contractorObjectNameFrom: string
          contractorObjectAddressFrom: string
          contractorObjectIdFrom: string

          idleFrom: boolean
          idleStartDateTimeFrom: string
          idleEndDateTimeFrom: string
          idleCommentFrom: string

          dateTimeTo: string

          contractorNameTo: string
          contractorInnTo: string
          contractorIdTo: string

          contractorObjectNameTo: string
          contractorObjectAddressTo: string
          contractorObjectIdTo: string

          idleTo: boolean
          idleStartDateTimeTo: string
          idleEndDateTimeTo: string
          idleCommentTo: string

          materialName: string
          contractorMaterialObjectId: string

          volumeFrom: number
          volumeTypeFrom?: number
          volumeTo: number
          volumeTypeTo?: number
          payment: number
          invoiceNumber: string
          waybillNumber: string

          completed: boolean

          companyId: string
          companyName: string
          ticketNumber: string
        }>(`/api/v1/trips/${tripId}`)

        setDateTimeFrom(toDateFromUtc(trip.data.dateTimeFrom))

        setContractorNameFrom(trip.data.contractorNameFrom)
        setContractorIdFrom(trip.data.contractorIdFrom)

        setContractorObjectNameFrom(trip.data.contractorObjectNameFrom)
        setContractorObjectIdFrom(trip.data.contractorObjectIdFrom)
        setContractorObjectId(trip.data.contractorObjectIdFrom)

        setIdleFrom(trip.data.idleFrom)
        setIdleStartDateTimeFrom(toDateFromUtc(trip.data.idleStartDateTimeFrom))
        setIdleEndDateTimeFrom(toDateFromUtc(trip.data.idleEndDateTimeFrom))
        setIdleCommentFrom(trip.data.idleCommentFrom)

        if (trip.data.dateTimeTo) {
          setDateTimeTo(toDateFromUtc(trip.data.dateTimeTo))
        }

        if (trip.data.contractorIdTo) {
          setContractorIdTo(trip.data.contractorIdTo)
          setContractorNameTo(trip.data.contractorNameTo)
          setContractorObjectNameTo(trip.data.contractorObjectNameTo)
          setContractorObjectIdTo(trip.data.contractorObjectIdTo)
        }
        setIdleTo(trip.data.idleTo)
        setIdleStartDateTimeTo(toDateFromUtc(trip.data.idleStartDateTimeTo))
        setIdleEndDateTimeTo(toDateFromUtc(trip.data.idleEndDateTimeTo))
        setIdleCommentTo(trip.data.idleCommentTo)

        if (trip.data.contractorMaterialObjectId) {
          setContractorMaterialObjectMaterialName(trip.data.materialName)
          setContractorMaterialObjectId(trip.data.contractorMaterialObjectId)
        }

        setVolumeFrom(trip.data.volumeFrom)
        setVolumeTypeFrom(trip.data.volumeTypeFrom || 0)
        setVolumeTo(trip.data.volumeTo)
        setToHidden(trip.data.completed)
        setVolumeTypeTo(trip.data.volumeTypeTo || 0)
        setPayment(trip.data.payment)
        setInvoiceNumber(trip.data.invoiceNumber)
        setWaybillNumber(trip.data.waybillNumber)

        if (trip.data.companyId) {
          setCompanyId(trip.data.companyId)
          setCompanyName(trip.data.companyName)
        }

        setTicketNumber(trip.data.ticketNumber)
      } finally {
        setDisabled(false)
      }
    }

    loadTrip()
  }, [tripId])

  useEffect(() => {
    const loadDocuments = async () => {
      setDisabled(true)

      try {
        const documents = await Axios.get<{
          items: { id: string; type: string; name: string }[]
          pages: number
        }>(`/api/v1/trips-documents/${tripId}?page=1`)

        setDocuments(documents.data.items)
      } finally {
        setDisabled(false)
      }
    }

    loadDocuments()
  }, [tripId])

  const editTrip = async (_: FormEvent) => {
    _.preventDefault()

    setDisabled(true)

    try {
      if (dateTimeTo && new Date(dateTimeFrom) > new Date(dateTimeTo)) {
        alert('Дата разгрузки должна быть больше или равна дате погрузки.')
        return
      }

      const formData = new FormData()

      formData.append('type', '0')
      formData.append('dateTimeFrom', toStringWithoutTimezone(dateTimeFrom))
      formData.append('contractorIdFrom', contractorIdFrom)
      formData.append('contractorObjectIdFrom', contractorObjectIdFrom)
      formData.append('contractorObjectId', contractorObjectId)
      formData.append('contractorIdTo', contractorIdTo)
      formData.append('contractorObjectIdTo', contractorObjectIdTo)
      formData.append(
        'contractorMaterialObjectId',
        contractorMaterialObjectId || ''
      )
      formData.append('volumeFrom', volumeFrom.toString())
      formData.append('volumeTypeFrom', volumeTypeFrom.toString())
      formData.append('idleFrom', idleFrom.toString())
      formData.append(
        'idleStartDateTimeFrom',
        toStringWithoutTimezone(idleStartDateTimeFrom)
      )
      formData.append(
        'idleEndDateTimeFrom',
        toStringWithoutTimezone(idleEndDateTimeFrom)
      )
      formData.append('idleCommentFrom', idleCommentFrom || '')
      formData.append('documentFrom', documentFrom || '')
      formData.append('documentTo', documentTo || '')
      formData.append('dateTimeTo', toStringWithoutTimezone(dateTimeTo))
      formData.append('idleTo', idleTo.toString())
      formData.append(
        'idleStartDateTimeTo',
        toStringWithoutTimezone(idleStartDateTimeTo)
      )
      formData.append(
        'idleEndDateTimeTo',
        toStringWithoutTimezone(idleEndDateTimeTo)
      )
      formData.append('idleCommentTo', idleCommentTo || '')
      formData.append('volumeTo', volumeTo?.toString() || '')
      formData.append('volumeTypeTo', volumeTypeTo.toString())
      formData.append('invoiceNumber', invoiceNumber || '')
      formData.append('waybillNumber', waybillNumber || '')
      formData.append('companyId', companyId || '')
      formData.append('ticketNumber', ticketNumber || '')

      await Axios.put<{ data: string }>(`/api/v1/trips/${tripId}`, formData)

      toast.success('Поездка обновлена.')

      history.push('/main')
    } finally {
      setDisabled(false)
    }
  }

  return (
    <>
      <StandardHeader
        iconClass={'bi bi-journal-arrow-down'}
        title={'Поставка'}
      />
      <form onSubmit={editTrip}>
        <fieldset disabled={disabled}>
          <div className="container-fluid mt-3">
            <div className="row">
              <div className="col">
                <strong>
                  {format(dateTimeFrom, 'dd.MM.yyyy HH:mm')}
                  {dateTimeTo ? (
                    <>&mdash;{format(dateTimeTo, 'dd.MM.yyyy HH:mm')}</>
                  ) : null}
                </strong>
                <br />
                {contractorObjectNameFrom}
                <br />
                <span className="text-muted">{contractorNameFrom}</span>
                <br />
                <i className="fas fa-arrow-right"></i>
                <br />
                {contractorObjectNameTo}
                <br />
                <span className="text-muted">{contractorNameTo}</span>
                <br />
                <span className="text-muted">{companyName}</span>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col">
                <strong>{contractorMaterialObjectMaterialName}</strong>
                <br />
                Погрузка:
                <NumberFormat
                  value={volumeFrom}
                  displayType={'text'}
                  thousandSeparator=" "
                  decimalSeparator=","
                  decimalScale={2}
                  fixedDecimalScale={true}
                  suffix={volumeTypeFrom === 0 ? ' Тонны' : ' М³'}
                />
                .
                {toHidden ? (
                  <>
                    <br />
                    Разгрузка:
                    <NumberFormat
                      value={volumeTo}
                      displayType={'text'}
                      thousandSeparator=" "
                      decimalSeparator=","
                      decimalScale={2}
                      fixedDecimalScale={true}
                      suffix={volumeTypeTo === 0 ? ' Тонны' : ' М³'}
                    />
                    .
                  </>
                ) : null}
              </div>
            </div>

            <div className="row mt-3">
              <div className="col">
                <strong>Документы</strong>
                <br />
                {documents != null && documents.length > 0 ? (
                  documents.map((_) => (
                    <Button
                      classList="btn btn-link"
                      text={_.name}
                      name={_.name}
                      breakpoint={'md'}
                      column={12}
                      action={async () => {
                        const response = await Axios.get(
                          `/api/v1/trips-documents/files/${_.id}`,
                          {
                            responseType: 'blob',
                          }
                        )

                        downloadFile(response)
                      }}
                    ></Button>
                  ))
                ) : (
                  <>
                    <span className="text-danger">Нет документов</span>
                    <div className="form-group" hidden={toHidden}>
                      <label>Фото накладной</label>
                      <input
                        className="form-control"
                        type="file"
                        accept="image/x-png,image/gif,image/jpeg"
                        onChange={(_) => {
                          if (!_.target.files) {
                            return
                          }

                          if (
                            _.target.files[0].size >
                            process.env.REACT_APP_UPLOAD_SIZE_LIMIT
                          ) {
                            alert(
                              `Выберите файл размером меньше ${
                                process.env.REACT_APP_UPLOAD_SIZE_LIMIT /
                                1000 /
                                1000
                              }МБ.`
                            )

                            return
                          }

                          setDocumentFrom(_.target.files[0])
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>

            <hr />

            <div className="row mt-3" hidden={!toHidden}>
              <div className="col">
                <strong>
                  Начислено
                  <NumberFormat
                    value={payment}
                    displayType={'text'}
                    thousandSeparator=" "
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale={true}
                    suffix=" ₽"
                  />
                </strong>
              </div>
            </div>

            <div className="row" hidden={toHidden}>
              <div className="col">
                <strong>Сведения о разгрузке</strong>
                <br />
                <div className="form-group">
                  <label>Сведения о разгрузке</label>
                  <DatePicker
                    required={true}
                    locale="ru"
                    className="form-control"
                    selected={dateTimeTo}
                    onChange={(date: Date) => setDateTimeTo(date)}
                    dateFormat="dd.MM.yyyy HH:mm"
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={30}
                    timeCaption="Время"
                    isClearable
                    minDate={dateTimeFrom}
                    maxDate={new Date()}
                    popperPlacement="bottom"
                    customInput={<ButtonDatePicker />}
                  />
                </div>

                <div className="form-row">
                  <div className="col">
                    <label>Объём</label>
                    <input
                      type="text"
                      className="form-control"
                      defaultValue={volumeTo}
                      required
                      onChange={(_) => {
                        _.target.value = toNumber(_.target.value)

                        const floatNumber = ruParseFloat(_.target.value)

                        if (
                          (volumeTypeTo === 0 && floatNumber > 60) ||
                          (volumeTypeTo === 1 && floatNumber > 60)
                        ) {
                          alert('Не более 60 тонн или 60 М³.')
                        }

                        setVolumeTo(floatNumber)
                      }}
                    />
                  </div>
                  <div className="col">
                    <label>Единица измерения</label>
                    <select
                      required
                      className="form-control"
                      value={volumeTypeTo}
                      onChange={(_) => {
                        const type = parseInt(_.target.value)

                        if (
                          (type === 0 && (volumeTo || 0) > 60) ||
                          (type === 1 && (volumeTo || 0) > 60)
                        ) {
                          alert('Не более 60 тонн или 60 М³.')
                        }

                        setVolumeTypeTo(type)
                      }}
                    >
                      <option value={0}>Тонны</option>
                      <option value={1}>М³</option>
                    </select>
                  </div>
                </div>

                <div className="form-group form-check mt-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={idleTo}
                    onChange={(_) => {
                      setIdleTo(!idleTo)
                    }}
                    id="idleTo"
                  />
                  <label className="form-check-label" htmlFor="idleTo">
                    <strong>Простой на объекте</strong>
                  </label>
                </div>

                {idleTo ? (
                  <>
                    <div className="form-group">
                      <label>С</label>
                      <DatePicker
                        required={true}
                        locale="ru"
                        className="form-control"
                        selected={idleStartDateTimeTo}
                        onChange={(date: Date) => setIdleStartDateTimeTo(date)}
                        dateFormat="dd.MM.yyyy HH:mm"
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={30}
                        timeCaption="Время"
                        isClearable
                        popperPlacement="bottom"
                        maxDate={new Date()}
                        customInput={<ButtonDatePicker />}
                      />
                    </div>
                    <div className="form-group">
                      <label>По</label>
                      <DatePicker
                        required={true}
                        locale="ru"
                        className="form-control"
                        selected={idleEndDateTimeTo}
                        onChange={(date: Date) => setIdleEndDateTimeTo(date)}
                        dateFormat="dd.MM.yyyy HH:mm"
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={30}
                        timeCaption="Время"
                        isClearable
                        minDate={idleStartDateTimeTo}
                        popperPlacement="bottom"
                        maxDate={new Date()}
                        customInput={<ButtonDatePicker />}
                      />
                    </div>
                    <div className="form-group">
                      <label>Комментарий при простое</label>
                      <textarea
                        className="form-control"
                        required
                        rows={5}
                        value={idleCommentTo}
                        onChange={(_) => setIdleCommentTo(_.target.value)}
                      ></textarea>
                    </div>
                  </>
                ) : null}

                <div className="form-group">
                  <label>Номер путевого листа</label>
                  <input
                    type="text"
                    className="form-control"
                    value={waybillNumber}
                    onChange={(_) => setWaybillNumber(_.target.value)}
                  />
                </div>

                <div className="form-group">
                  <label>Номер накладной</label>
                  <input
                    type="text"
                    className="form-control"
                    value={invoiceNumber}
                    onChange={(_) => setInvoiceNumber(_.target.value)}
                    required
                  />
                </div>

                <div className="form-group">
                  <label>Фото накладной</label>
                  <input
                    className="form-control"
                    type="file"
                    accept="image/x-png,image/gif,image/jpeg"
                    onChange={(_) => {
                      if (!_.target.files) {
                        return
                      }

                      if (
                        _.target.files[0].size >
                        process.env.REACT_APP_UPLOAD_SIZE_LIMIT
                      ) {
                        alert(
                          `Выберите файл размером меньше ${
                            process.env.REACT_APP_UPLOAD_SIZE_LIMIT /
                            1000 /
                            1000
                          }МБ.`
                        )

                        return
                      }

                      setDocumentTo(_.target.files[0])
                    }}
                  />
                </div>

                <div className="h6 font-weight-bold">Номер талона</div>
                <div className="form-group">
                  <label>Номер</label>
                  <input
                    type="text"
                    className="form-control"
                    value={ticketNumber}
                    onChange={(_) => setTicketNumber(_.target.value || '')}
                  />
                </div>

                <div className="form-group">
                  <Button
                    text={'Сохранить'}
                    classList={'btn btn-block btn-lg btn-success'}
                    name={'supplyForm'}
                    breakpoint={'md'}
                    column={12}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="form-group">
                  <Link
                    to="/main"
                    className="btn btn-block btn-outline-secondary"
                    hidden={queryParams.backurl === 'history'}
                  >
                    На главную
                  </Link>

                  <Link
                    to="/history"
                    className="btn btn-block btn-outline-secondary"
                    hidden={queryParams.backurl !== 'history'}
                  >
                    К истории заказов
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </form>
    </>
  )
}
