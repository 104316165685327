import React, { FormEvent, useState } from 'react'
import StandardHeader from '../../Molecules/StandardHeader/StandardHeader'
import Button from '../../Atoms/Button/Button'
import DatePicker from 'react-datepicker'
import AsyncSelect from 'react-select/async'
import Axios from 'axios'
import { Link, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { toStringWithoutTimezone } from '../../toStringWithoutTimezone'
import { toNumber } from '../../Api/document/toNumber'
import { ruParseFloat } from '../../Api/document/ruParseFloat'
import { ButtonDatePicker } from '../../Api/document/buttonDatePicker'

export const AddShipment = () => {
  const [dateTimeFrom, setDateTimeFrom] = useState<Date>()
  const [contractorIdFrom, setContractorIdFrom] = useState('')
  const [contractorObjectIdFrom, setContractorObjectIdFrom] = useState('')
  const [contractorObjectId, setContractorObjectId] = useState('')
  const [contractorIdTo, setContractorIdTo] = useState('')
  const [contractorObjectIdTo, setContractorObjectIdTo] = useState('')
  const [
    contractorMaterialObjectId,
    setContractorMaterialObjectId,
  ] = useState<string>()
  const [volumeFrom, setVolumeFrom] = useState<number>()
  const [volumeTypeFrom, setVolumeTypeFrom] = useState(0)
  const [idleFrom, setIdleFrom] = useState(false)
  const [idleEndDateTimeFrom, setIdleEndDateTimeFrom] = useState<Date>()
  const [idleStartDateTimeFrom, setIdleStartDateTimeFrom] = useState<Date>()
  const [idleCommentFrom, setIdleCommentFrom] = useState<string>()
  const [documentFrom, setDocumentFrom] = useState<Blob>()
  const [disabled, setDisabled] = useState(false)
  const [companyId, setCompanyId] = useState('')
  const [ticketNumber, setTicketNumber] = useState('')

  const history = useHistory()

  const addShipment = async (_: FormEvent) => {
    _.preventDefault()

    setDisabled(true)

    try {
      const formData = new FormData()

      formData.append('type', '1')
      formData.append('dateTimeFrom', toStringWithoutTimezone(dateTimeFrom))
      formData.append('contractorIdFrom', contractorIdFrom)
      formData.append('contractorObjectIdFrom', contractorObjectIdFrom)
      formData.append('contractorObjectId', contractorObjectId)
      formData.append('contractorIdTo', contractorIdTo)
      formData.append('contractorObjectIdTo', contractorObjectIdTo)
      formData.append(
        'contractorMaterialObjectId',
        contractorMaterialObjectId || ''
      )
      formData.append('volumeFrom', volumeFrom?.toString() || '0')
      formData.append('volumeTypeFrom', volumeTypeFrom.toString())
      formData.append('idleFrom', idleFrom.toString())
      formData.append(
        'idleEndDateTimeFrom',
        toStringWithoutTimezone(idleEndDateTimeFrom)
      )
      formData.append(
        'idleStartDateTimeFrom',
        toStringWithoutTimezone(idleStartDateTimeFrom)
      )
      formData.append('idleCommentFrom', idleCommentFrom || '')
      formData.append('documentFrom', documentFrom || '')
      formData.append('companyId', companyId || '')
      formData.append('ticketNumber', ticketNumber || '')

      const trip = await Axios.post<{ data: string }>('/api/v1/trips', formData)

      toast.success('Перевозка добавлена.')

      history.push(`/edit-shipment/${trip.data.data}`)
    } finally {
      setDisabled(false)
    }
  }

  return (
    <>
      <StandardHeader
        iconClass={'bi bi-journal-arrow-down'}
        title={'Перевозка'}
      />

      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col">
            <form onSubmit={addShipment}>
              <fieldset disabled={disabled}>
                <div className="form-group">
                  <label>Начало погрузки</label>
                  <DatePicker
                    required={true}
                    locale="ru"
                    className="form-control"
                    selected={dateTimeFrom}
                    onChange={(date: Date) => setDateTimeFrom(date)}
                    dateFormat="dd.MM.yyyy HH:mm"
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={30}
                    timeCaption="Время"
                    isClearable
                    popperPlacement="bottom"
                    customInput={<ButtonDatePicker />}
                  />
                </div>

                <div className="h6 font-weight-bold">Точка погрузки</div>
                <div className="form-group">
                  <label>Адрес</label>
                  <AsyncSelect
                    defaultOptions
                    loadOptions={(inputValue: string, callback: any) => {
                      const loadContractorsObjects = async (
                        inputValue: string,
                        callback: any
                      ) => {
                        const contractorsObjects = await Axios.get<{
                          items: {
                            id: string
                            name: string
                            address: string
                            contractorId: string
                            contractorName: string
                          }[]
                        }>(
                          `/api/v1/contractors-objects?query=${inputValue}&page=1`
                        )

                        callback(
                          contractorsObjects.data.items.map((_) => {
                            return {
                              value: _.id,
                              label: `${_.name}-${_.address}-${_.contractorName}`,
                              contractorId: _.contractorId,
                            }
                          })
                        )
                      }

                      loadContractorsObjects(inputValue, callback)
                    }}
                    onChange={(_: any) => {
                      setContractorObjectId(_.value)
                      setContractorObjectIdFrom(_.value)
                    }}
                  />
                  <input
                    type="text"
                    value={contractorObjectIdFrom ? '-' : ''}
                    style={{ height: 0, opacity: 0 }}
                    required
                  />
                </div>
                <div
                  className="form-group"
                  hidden={!contractorObjectIdFrom}
                  style={{ marginTop: -24 }}
                >
                  <label>Наименование контрагента</label>
                  <AsyncSelect
                    key={contractorObjectIdFrom}
                    cacheOptions
                    defaultOptions
                    loadOptions={(inputValue: string, callback: any) => {
                      const loadContractors = async (
                        inputValue: string,
                        callback: any
                      ) => {
                        const contractors = await Axios.get<{
                          items: {
                            id: string
                            name: string
                            inn: string
                          }[]
                        }>(
                          `/api/v1/contractors?query=${inputValue}&contractorObjectId=${
                            contractorObjectIdFrom || ''
                          }&page=1`
                        )

                        callback(
                          contractors.data.items.map((_) => {
                            return {
                              value: _.id,
                              label: `${_.name}`,
                            }
                          })
                        )
                      }

                      loadContractors(inputValue, callback)
                    }}
                    onChange={(_: any) => {
                      setContractorIdFrom(_.value)
                    }}
                  />
                  <input
                    type="text"
                    value={contractorIdFrom ? '-' : ''}
                    style={{ height: 0, opacity: 0 }}
                    required
                  />
                </div>

                <div className="h6 font-weight-bold">Точка разгрузки</div>
                <div className="form-group">
                  <label>Адрес</label>
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    loadOptions={(inputValue: string, callback: any) => {
                      const loadContractorsObjects = async (
                        inputValue: string,
                        callback: any
                      ) => {
                        const contractorsObjects = await Axios.get<{
                          items: {
                            id: string
                            name: string
                            address: string
                            contractorName: string
                          }[]
                        }>(
                          `/api/v1/contractors-objects?query=${inputValue}&page=1`
                        )

                        callback(
                          contractorsObjects.data.items.map((_) => {
                            return {
                              value: _.id,
                              label: `${_.name}-${_.address}-${_.contractorName}`,
                            }
                          })
                        )
                      }

                      loadContractorsObjects(inputValue, callback)
                    }}
                    onChange={(_: any) => {
                      setContractorObjectIdTo(_.value)
                    }}
                  />
                  <input
                    type="text"
                    value={contractorObjectIdTo ? '-' : ''}
                    style={{ height: 0, opacity: 0 }}
                    required
                  />
                </div>
                <div
                  className="form-group"
                  hidden={!contractorObjectIdTo}
                  style={{ marginTop: -24 }}
                >
                  <label>Наименование контрагента</label>
                  <AsyncSelect
                    key={contractorObjectIdTo}
                    cacheOptions
                    defaultOptions
                    loadOptions={(inputValue: string, callback: any) => {
                      const loadContractors = async (
                        inputValue: string,
                        callback: any
                      ) => {
                        const contractors = await Axios.get<{
                          items: {
                            id: string
                            name: string
                            inn: string
                          }[]
                        }>(
                          `/api/v1/contractors?query=${inputValue}&contractorObjectId=${
                            contractorObjectIdTo || ''
                          }&page=1`
                        )

                        callback(
                          contractors.data.items.map((_) => {
                            return {
                              value: _.id,
                              label: `${_.name}`,
                            }
                          })
                        )
                      }

                      loadContractors(inputValue, callback)
                    }}
                    onChange={(_: any) => {
                      setContractorIdTo(_.value)
                    }}
                  />
                  <input
                    type="text"
                    value={contractorIdTo ? '-' : ''}
                    style={{ height: 0, opacity: 0, display: 'none' }}
                    required
                  />
                </div>

                <div className="h6 font-weight-bold">Сведения о загрузке</div>
                <div className="form-group">
                  <label>Материал</label>
                  <AsyncSelect
                    key={contractorObjectIdTo}
                    isDisabled={!contractorObjectIdTo}
                    cacheOptions
                    defaultOptions
                    loadOptions={(inputValue: string, callback: any) => {
                      const loadMaterials = async (
                        inputValue: string,
                        callback: any
                      ) => {
                        const materials = await Axios.get<{
                          items: {
                            id: string
                            materialName: string
                          }[]
                        }>(
                          `/api/v1/contractors-materials-objects?query=${inputValue}&contractorObjectId=${contractorObjectIdTo}&page=1`
                        )

                        callback(
                          materials.data.items.map((_) => {
                            return {
                              value: _.id,
                              label: _.materialName,
                            }
                          })
                        )
                      }

                      loadMaterials(inputValue, callback)
                    }}
                    onChange={(_: any) => {
                      if (_) {
                        setContractorMaterialObjectId(_.value)
                        return
                      }

                      setContractorMaterialObjectId(undefined)
                    }}
                  />
                  <input
                    type="text"
                    value={contractorMaterialObjectId ? '-' : ''}
                    style={{ height: 0, opacity: 0 }}
                    required
                  />
                </div>

                <div className="form-row" style={{ marginTop: -24 }}>
                  <div className="col">
                    <label>Объём</label>
                    <input
                      type="text"
                      className="form-control"
                      defaultValue={volumeFrom}
                      required
                      onChange={(_) => {
                        _.target.value = toNumber(_.target.value)

                        const floatNumber = ruParseFloat(_.target.value)

                        if (
                          (volumeTypeFrom === 0 && floatNumber > 60) ||
                          (volumeTypeFrom === 1 && floatNumber > 60)
                        ) {
                          alert('Не более 60 тонн или 60 М³.')
                        }

                        setVolumeFrom(floatNumber)
                      }}
                    />
                  </div>
                  <div className="col">
                    <label>Единица измерения</label>
                    <select
                      required
                      className="form-control"
                      value={volumeTypeFrom}
                      onChange={(_) => {
                        const type = parseInt(_.target.value)

                        if (
                          (type === 0 && (volumeFrom || 0) > 60) ||
                          (type === 1 && (volumeFrom || 0) > 60)
                        ) {
                          alert('Не более 60 тонн или 60 М³.')
                        }

                        setVolumeTypeFrom(type)
                      }}
                    >
                      <option value={0}>Тонны</option>
                      <option value={1}>М³</option>
                    </select>
                  </div>
                </div>

                <div className="form-group form-check mt-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={idleFrom}
                    onChange={(_) => {
                      setIdleFrom(!idleFrom)
                    }}
                    id="idleFrom"
                  />
                  <label className="form-check-label" htmlFor="idleFrom">
                    <strong>Простой на объекте</strong>
                  </label>
                </div>

                {idleFrom ? (
                  <>
                    <div className="form-group">
                      <label>С</label>
                      <DatePicker
                        required={true}
                        locale="ru"
                        className="form-control"
                        selected={idleStartDateTimeFrom}
                        onChange={(date: Date) =>
                          setIdleStartDateTimeFrom(date)
                        }
                        dateFormat="dd.MM.yyyy HH:mm"
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={30}
                        timeCaption="Время"
                        isClearable
                        popperPlacement="bottom"
                        maxDate={new Date()}
                        customInput={<ButtonDatePicker />}
                      />
                    </div>
                    <div className="form-group">
                      <label>По</label>
                      <DatePicker
                        required={true}
                        locale="ru"
                        className="form-control"
                        selected={idleEndDateTimeFrom}
                        onChange={(date: Date) => setIdleEndDateTimeFrom(date)}
                        dateFormat="dd.MM.yyyy HH:mm"
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={30}
                        timeCaption="Время"
                        isClearable
                        minDate={idleStartDateTimeFrom}
                        popperPlacement="bottom"
                        maxDate={new Date()}
                        customInput={<ButtonDatePicker />}
                      />
                    </div>
                    <div className="form-group">
                      <label>Комментарий при простое</label>
                      <textarea
                        className="form-control"
                        required
                        rows={5}
                        value={idleCommentFrom}
                        onChange={(_) => setIdleCommentFrom(_.target.value)}
                      ></textarea>
                    </div>
                  </>
                ) : null}

                <div className="form-group">
                  <label>Фото накладной</label>
                  <input
                    className="form-control"
                    type="file"
                    accept="image/x-png,image/gif,image/jpeg"
                    onChange={(_) => {
                      if (!_.target.files) {
                        return
                      }

                      if (
                        _.target.files[0].size >
                        process.env.REACT_APP_UPLOAD_SIZE_LIMIT
                      ) {
                        alert(
                          `Выберите файл размером меньше ${
                            process.env.REACT_APP_UPLOAD_SIZE_LIMIT /
                            1000 /
                            1000
                          }МБ.`
                        )

                        return
                      }

                      setDocumentFrom(_.target.files[0])
                    }}
                  />
                </div>

                <div className="h6 font-weight-bold">Организация</div>
                <div className="form-group">
                  <label>Организация</label>
                  <AsyncSelect
                    defaultOptions
                    isClearable={true}
                    loadOptions={(inputValue: string, callback: any) => {
                      const loadCompanies = async (
                        inputValue: string,
                        callback: any
                      ) => {
                        const companies = await Axios.get<{
                          items: {
                            id: string
                            name: string
                          }[]
                        }>(`/api/v1/companies?query=${inputValue}&page=1`)

                        callback(
                          companies.data.items.map((_) => {
                            return {
                              value: _.id,
                              label: _.name,
                            }
                          })
                        )
                      }

                      loadCompanies(inputValue, callback)
                    }}
                    onChange={(_: any) => {
                      if (_) {
                        setCompanyId(_.value)
                        return
                      }

                      setCompanyId('')
                    }}
                  />
                  <input
                    type="text"
                    value={contractorObjectIdFrom ? '-' : ''}
                    style={{ height: 0, opacity: 0 }}
                    required
                  />
                </div>

                <div className="h6 font-weight-bold">Номер талона</div>
                <div className="form-group">
                  <label>Номер</label>
                  <input
                    type="text"
                    className="form-control"
                    value={ticketNumber}
                    onChange={(_) => setTicketNumber(_.target.value || '')}
                  />
                </div>

                <div className="form-group">
                  <Button
                    text={'Сохранить'}
                    classList={'btn btn-block btn-lg btn-success'}
                    name={'shipmentForm'}
                    breakpoint={'md'}
                    column={12}
                  />
                </div>

                <div className="form-group">
                  <Link
                    to="/main"
                    className="btn btn-block btn-outline-secondary"
                  >
                    На главную
                  </Link>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
