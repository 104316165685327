import React from 'react'

type ButtonProps = {
  /** Текст внутри кнопки */
  text: string
  /** классы кнопки из  Bootstrap 4 */
  classList: string
  /** Название кнопки, которое передаётся при отправке с данными формы */
  name: string
  /** Bootstrap 4  брейкпоинт (sm, md, lg, xl)*/
  breakpoint: string
  /** ширина колонки по Bootstrap 4 (от 1 до 12)*/
  column: number
  /** действие по клику */
  action?: () => void
  disabled?: boolean
}

const Button = ({
  text,
  classList,
  name,
  breakpoint,
  column,
  action,
  disabled,
}: ButtonProps) => {
  return (
    <div className={`row`}>
      <div className={`col col-${breakpoint}-${column} mx-auto`}>
        <button
          className={classList}
          name={name}
          onClick={action}
          disabled={disabled}
        >
          {text}
        </button>
      </div>
    </div>
  )
}

export default Button
