import React, { useEffect, useState } from 'react'
import StandardHeader from '../../Molecules/StandardHeader/StandardHeader'
import Button from '../../Atoms/Button/Button'
import Axios from 'axios'
import { Link, useParams } from 'react-router-dom'
import { format } from 'date-fns'
import { downloadFile } from '../../Api/document/downloadFile'
import queryString from 'query-string'
import NumberFormat from 'react-number-format'
import { toDateFromUtc } from '../../toDateFromUtc'

export const EditRent = () => {
  const [dateTimeFrom, setDateTimeFrom] = useState(new Date())
  const [dateTimeTo, setDateTimeTo] = useState(new Date())
  const [contractorNameFrom, setContractorNameFrom] = useState('')
  const [contractorObjectNameFrom, setContractorObjectNameFrom] = useState('')
  const [invoiceNumber, setInvoiceNumber] = useState<string>()
  const [waybillNumber, setWaybillNumber] = useState<string>()
  const [companyName, setCompanyName] = useState('')
  const [payment, setPayment] = useState(0)
  const { tripId } = useParams<{ tripId: string }>()
  const [documents, setDocuments] = useState<
    { id: string; type: string; name: string }[]
  >()
  const [ticketNumber, setTicketNumber] = useState('')

  useEffect(() => {
    const loadTrip = async () => {
      try {
        const trip = await Axios.get<{
          dateTimeFrom: string

          contractorNameFrom: string
          contractorInnFrom: string
          contractorIdFrom: string

          contractorObjectNameFrom: string
          contractorObjectAddressFrom: string
          contractorObjectIdFrom: string

          dateTimeTo: string

          volumeFrom: number
          volumeTypeFrom: number
          volumeTo: number
          volumeTypeTo: number
          payment: number
          invoiceNumber: string
          waybillNumber: string
          companyName: string
          ticketNumber: string
        }>(`/api/v1/trips/${tripId}`)

        setDateTimeFrom(toDateFromUtc(trip.data.dateTimeFrom))

        setContractorNameFrom(trip.data.contractorNameFrom)

        setContractorObjectNameFrom(trip.data.contractorObjectNameFrom)

        if (trip.data.dateTimeTo) {
          setDateTimeTo(toDateFromUtc(trip.data.dateTimeTo))
        }

        setPayment(trip.data.payment)
        setWaybillNumber(trip.data.waybillNumber)
        setInvoiceNumber(trip.data.invoiceNumber)

        if (trip.data.companyName) {
          setCompanyName(trip.data.companyName)
        }

        setTicketNumber(trip.data.ticketNumber)
      } finally {
      }
    }

    loadTrip()
  }, [tripId])

  useEffect(() => {
    const loadDocuments = async () => {
      try {
        const documents = await Axios.get<{
          items: { id: string; type: string; name: string }[]
          pages: number
        }>(`/api/v1/trips-documents/${tripId}?page=1`)

        setDocuments(documents.data.items)
      } finally {
      }
    }

    loadDocuments()
  }, [tripId])

  const queryParams = queryString.parse(window.location.search)

  return (
    <>
      <StandardHeader iconClass={'bi bi-journal-arrow-down'} title={'Аренда'} />
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col">
            <strong>
              {format(dateTimeFrom, 'dd.MM.yyyy HH:mm')}&mdash;
              {format(dateTimeTo, 'dd.MM.yyyy HH:mm')}
            </strong>
            <br />
            {contractorObjectNameFrom}
            <br />
            <span className="text-muted">{contractorNameFrom}</span>
            <br />
            {companyName}
          </div>
        </div>

        <div className="row mt-3">
          <div className="col">
            <strong>Документы</strong>
            <br />
            {invoiceNumber} / {waybillNumber}
            <br />
            {ticketNumber}
            <br />
            {documents?.map((_) => (
              <Button
                classList="btn btn-link"
                text={_.name}
                name={_.name}
                breakpoint={'md'}
                column={12}
                action={async () => {
                  const response = await Axios.get(
                    `/api/v1/trips-documents/files/${_.id}`,
                    {
                      responseType: 'blob',
                    }
                  )

                  downloadFile(response)
                }}
              ></Button>
            ))}
          </div>
        </div>

        <hr />

        <div className="row mt-3">
          <div className="col">
            <strong>
              Начислено
              <NumberFormat
                value={payment}
                displayType={'text'}
                thousandSeparator=" "
                decimalSeparator=","
                decimalScale={2}
                fixedDecimalScale={true}
                suffix=" ₽"
              />
            </strong>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="form-group">
              <Link
                to="/main"
                className="btn btn-block btn-outline-secondary"
                hidden={queryParams.backurl === 'history'}
              >
                На главную
              </Link>

              <Link
                to="/history"
                className="btn btn-block btn-outline-secondary"
                hidden={queryParams.backurl !== 'history'}
              >
                К истории заказов
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
