import DatePicker from 'react-datepicker'
import { FormEvent, useEffect, useState } from 'react'
import StandardHeader from '../../Molecules/StandardHeader/StandardHeader'
import Button from '../../Atoms/Button/Button'
import Axios from 'axios'
import { Link, useHistory, useParams } from 'react-router-dom'
import AsyncSelect from 'react-select/async'
import { toStringWithoutTimezone } from '../../toStringWithoutTimezone'
import { toast } from 'react-toastify'
import { ButtonDatePicker } from '../../Api/document/buttonDatePicker'
import { toDateFromUtc } from '../../toDateFromUtc'

export const EditRentHistory = () => {
  const [dateTimeFrom, setDateTimeFrom] = useState(new Date())
  const [dateTimeTo, setDateTimeTo] = useState(new Date())
  const [contractorNameFrom, setContractorNameFrom] = useState('')
  const [contractorObjectNameFrom, setContractorObjectNameFrom] = useState('')
  const [
    contractorObjectAddressFrom,
    setContractorObjectAddressFrom,
  ] = useState('')
  const [invoiceNumber, setInvoiceNumber] = useState<string>()
  const [waybillNumber, setWaybillNumber] = useState<string>()
  const [companyId, setCompanyId] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [contractorIdFrom, setContractorIdFrom] = useState('')
  const [contractorObjectIdFrom, setContractorObjectIdFrom] = useState('')
  const [contractorObjectId, setContractorObjectId] = useState('')
  const [documentFrom, setDocumentFrom] = useState<Blob>()
  const [documentTo, setDocumentTo] = useState<Blob>()
  const { tripId } = useParams<{ tripId: string }>()
  const [disabled, setDisabled] = useState(false)
  const [ticketNumber, setTicketNumber] = useState('')

  const history = useHistory()

  const editRent = async (_: FormEvent) => {
    _.preventDefault()

    setDisabled(true)

    try {
      const formData = new FormData()

      if (!dateTimeFrom) {
        alert('Заполните дату начала аренды.')
        return
      }

      formData.append('type', '2')
      formData.append('dateTimeFrom', toStringWithoutTimezone(dateTimeFrom))
      formData.append('dateTimeTo', toStringWithoutTimezone(dateTimeTo))
      formData.append('contractorIdFrom', contractorIdFrom)
      formData.append('contractorObjectIdFrom', contractorObjectIdFrom)
      formData.append('contractorObjectId', contractorObjectId)
      formData.append('documentFrom', documentFrom || '')
      formData.append('documentTo', documentTo || '')
      formData.append('invoiceNumber', invoiceNumber || '')
      formData.append('waybillNumber', waybillNumber || '')
      formData.append('companyId', companyId || '')
      formData.append('ticketNumber', ticketNumber || '')

      const trip = await Axios.put<{ data: string }>(
        `/api/v1/trips/${tripId}`,
        formData
      )

      toast.success('Аренда изменена.')

      history.push(`/edit-rent/${trip.data.data}`)
    } finally {
      setDisabled(false)
    }
  }

  useEffect(() => {
    const loadTrip = async () => {
      try {
        const trip = await Axios.get<{
          dateTimeFrom: string

          contractorNameFrom: string
          contractorInnFrom: string
          contractorIdFrom: string

          contractorObjectNameFrom: string
          contractorObjectAddressFrom: string
          contractorObjectIdFrom: string

          dateTimeTo: string

          volumeFrom: number
          volumeTypeFrom: number
          volumeTo: number
          volumeTypeTo: number
          payment: number
          invoiceNumber: string
          waybillNumber: string
          companyId: string
          companyName: string
          ticketNumber: string
        }>(`/api/v1/trips/${tripId}`)

        setDateTimeFrom(toDateFromUtc(trip.data.dateTimeFrom))

        setContractorNameFrom(trip.data.contractorNameFrom)
        setContractorIdFrom(trip.data.contractorIdFrom)

        setContractorObjectNameFrom(trip.data.contractorObjectNameFrom)
        setContractorObjectIdFrom(trip.data.contractorObjectIdFrom)
        setContractorObjectId(trip.data.contractorObjectIdFrom)
        setContractorObjectAddressFrom(trip.data.contractorObjectAddressFrom)

        if (trip.data.dateTimeTo) {
          setDateTimeTo(toDateFromUtc(trip.data.dateTimeTo))
        }

        setInvoiceNumber(trip.data.invoiceNumber)
        setWaybillNumber(trip.data.waybillNumber)

        if (trip.data.companyId) {
          setCompanyId(trip.data.companyId)
          setCompanyName(trip.data.companyName)
        }
        setTicketNumber(trip.data.ticketNumber)
      } finally {
      }
    }

    loadTrip()
  }, [tripId])

  return (
    <>
      <StandardHeader iconClass={'bi bi-journal-arrow-down'} title={'Аренда'} />
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col">
            <form onSubmit={editRent}>
              <fieldset disabled={disabled}>
                <div className="h6 font-weight-bold">Контрагент</div>
                <div className="form-group">
                  <label>Адрес</label>
                  <AsyncSelect
                    defaultOptions
                    loadOptions={(inputValue: string, callback: any) => {
                      const loadContractorsObjects = async (
                        inputValue: string,
                        callback: any
                      ) => {
                        const contractorsObjects = await Axios.get<{
                          items: {
                            id: string
                            name: string
                            address: string
                            contractorId: string
                            contractorName: string
                          }[]
                        }>(
                          `/api/v1/contractors-objects?query=${inputValue}&page=1`
                        )

                        callback(
                          contractorsObjects.data.items.map((_) => {
                            return {
                              value: _.id,
                              label: `${_.name}-${_.address}-${_.contractorName}`,
                              contractorId: _.contractorId,
                              name: _.name,
                              address: _.address,
                              contractorName: _.contractorName,
                            }
                          })
                        )
                      }

                      loadContractorsObjects(inputValue, callback)
                    }}
                    onChange={(_: any) => {
                      setContractorObjectId(_.value)
                      setContractorObjectIdFrom(_.value)
                      setContractorObjectNameFrom(_.name)
                      setContractorObjectAddressFrom(_.address)
                      setContractorIdFrom(_.contractorId)
                      setContractorNameFrom(_.contractorName)
                    }}
                    value={{
                      value: contractorObjectIdFrom,
                      label: `${contractorObjectNameFrom}-${contractorObjectAddressFrom}-${contractorNameFrom}`,
                      contractorId: contractorIdFrom,
                    }}
                  />
                  <input
                    type="text"
                    value={contractorObjectIdFrom ? '-' : ''}
                    style={{ height: 0, opacity: 0 }}
                    required
                  />
                </div>
                <div
                  className="form-group"
                  hidden={!contractorObjectIdFrom}
                  style={{ marginTop: -24 }}
                >
                  <label>Наименование контрагента</label>
                  <AsyncSelect
                    key={contractorObjectIdFrom}
                    cacheOptions
                    defaultOptions
                    loadOptions={(inputValue: string, callback: any) => {
                      const loadContractors = async (
                        inputValue: string,
                        callback: any
                      ) => {
                        const contractors = await Axios.get<{
                          items: {
                            id: string
                            name: string
                            inn: string
                          }[]
                        }>(
                          `/api/v1/contractors?query=${inputValue}&contractorObjectId=${
                            contractorObjectIdFrom || ''
                          }&page=1`
                        )

                        callback(
                          contractors.data.items.map((_) => {
                            return {
                              value: _.id,
                              label: `${_.name}`,
                            }
                          })
                        )
                      }

                      loadContractors(inputValue, callback)
                    }}
                    onChange={(_: any) => {
                      setContractorIdFrom(_.value)
                      setContractorNameFrom(_.label)
                    }}
                    value={{
                      value: contractorIdFrom,
                      label: contractorNameFrom,
                    }}
                  />
                  <input
                    type="text"
                    value={contractorIdFrom ? '-' : ''}
                    style={{ height: 0, opacity: 0 }}
                    required
                  />
                </div>

                <div className="form-group">
                  <label>
                    <strong>Начало</strong>
                  </label>
                  <DatePicker
                    required={true}
                    locale="ru"
                    className="form-control"
                    selected={dateTimeFrom}
                    onChange={(date: Date) => setDateTimeFrom(date)}
                    dateFormat="dd.MM.yyyy HH:mm"
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={30}
                    timeCaption="Время"
                    isClearable
                    popperPlacement="bottom"
                    maxDate={new Date()}
                    customInput={<ButtonDatePicker />}
                  />
                </div>

                <div className="form-group">
                  <label>
                    <strong>Конец</strong>
                  </label>
                  <DatePicker
                    required={true}
                    locale="ru"
                    className="form-control"
                    selected={dateTimeTo}
                    onChange={(date: Date) => setDateTimeTo(date)}
                    dateFormat="dd.MM.yyyy HH:mm"
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={30}
                    timeCaption="Время"
                    isClearable
                    minDate={dateTimeFrom}
                    maxDate={new Date()}
                    popperPlacement="bottom"
                    customInput={<ButtonDatePicker />}
                  />
                </div>

                <div className="h6 font-weight-bold">Номер путевого листа</div>
                <div className="form-group">
                  <label>Номер</label>
                  <input
                    type="text"
                    className="form-control"
                    value={waybillNumber}
                    onChange={(_) => setWaybillNumber(_.target.value)}
                  />
                </div>

                <div className="h6 font-weight-bold">Путевой лист</div>
                <div className="form-group">
                  <label>Номер</label>
                  <input
                    type="text"
                    className="form-control"
                    value={invoiceNumber}
                    onChange={(_) => setInvoiceNumber(_.target.value)}
                    required
                  />
                </div>

                <div className="h6 font-weight-bold">Номер талона</div>
                <div className="form-group">
                  <label>Номер</label>
                  <input
                    type="text"
                    className="form-control"
                    value={ticketNumber}
                    onChange={(_) => setTicketNumber(_.target.value || '')}
                  />
                </div>

                <div className="form-group">
                  <label>Лицевая сторона</label>
                  <input
                    className="form-control"
                    type="file"
                    accept="image/x-png,image/gif,image/jpeg"
                    onChange={(_) => {
                      if (!_.target.files) {
                        return
                      }

                      if (
                        _.target.files[0].size >
                        process.env.REACT_APP_UPLOAD_SIZE_LIMIT
                      ) {
                        alert(
                          `Выберите файл размером меньше ${
                            process.env.REACT_APP_UPLOAD_SIZE_LIMIT /
                            1000 /
                            1000
                          }МБ.`
                        )

                        return
                      }

                      setDocumentFrom(_.target.files[0])
                    }}
                  />
                </div>

                <div className="form-group">
                  <label>Обратная сторона</label>
                  <input
                    className="form-control"
                    type="file"
                    accept="image/x-png,image/gif,image/jpeg"
                    onChange={(_) => {
                      if (!_.target.files) {
                        return
                      }

                      if (
                        _.target.files[0].size >
                        process.env.REACT_APP_UPLOAD_SIZE_LIMIT
                      ) {
                        alert(
                          `Выберите файл размером меньше ${
                            process.env.REACT_APP_UPLOAD_SIZE_LIMIT /
                            1000 /
                            1000
                          }МБ.`
                        )

                        return
                      }

                      setDocumentTo(_.target.files[0])
                    }}
                  />
                </div>

                <div className="h6 font-weight-bold">Организация</div>
                <div className="form-group">
                  <label>Организация</label>
                  <AsyncSelect
                    defaultOptions
                    isClearable={true}
                    value={{
                      value: companyId,
                      label: companyName,
                    }}
                    loadOptions={(inputValue: string, callback: any) => {
                      const loadCompanies = async (
                        inputValue: string,
                        callback: any
                      ) => {
                        const companies = await Axios.get<{
                          items: {
                            id: string
                            name: string
                          }[]
                        }>(`/api/v1/companies?query=${inputValue}&page=1`)

                        callback(
                          companies.data.items.map((_) => {
                            return {
                              value: _.id,
                              label: _.name,
                            }
                          })
                        )
                      }

                      loadCompanies(inputValue, callback)
                    }}
                    onChange={(_: any) => {
                      if (_) {
                        setCompanyId(_.value)
                        setCompanyName(_.label)
                        return
                      }

                      setCompanyId('')
                      setCompanyName('')
                    }}
                  />
                  <input
                    type="text"
                    value={contractorObjectIdFrom ? '-' : ''}
                    style={{ height: 0, opacity: 0 }}
                    required
                  />
                </div>

                <div className="form-group">
                  <Button
                    text={'Сохранить'}
                    classList={'btn btn-block btn-lg btn-success'}
                    name={'rentForm'}
                    breakpoint={'md'}
                    column={12}
                  />
                </div>

                <div className="form-group">
                  <Link
                    to="/main"
                    className="btn btn-block btn-outline-secondary"
                  >
                    На главную
                  </Link>
                </div>
              </fieldset>
            </form>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="form-group">
              <Link
                to="/history"
                className="btn btn-block btn-outline-secondary"
              >
                К истории заказов
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
