import React, { useEffect, useState } from 'react'
import ProfileHeader from '../../Molecules/ProfileHeader/ProfileHeader'
import Button from '../../Atoms/Button/Button'
import CountBadge from '../../Atoms/CountBadge/CountBadge'
import LinkBtn from '../../Atoms/LinkBtn/LinkBtn'
import Auth from '../../Api/Auth/Auth'
import Axios from 'axios'

type MainProps = {
  /** Bootstrap 4  брейкпоинт (sm, md, lg, xl)*/
  breakpoint: string
  /** ширина колонки по Bootstrap 4 (от 1 до 12)*/
  column: number
}

const Main = ({ breakpoint, column }: MainProps) => {
  const user = Auth()

  /** ФИО водителя */
  const fullName = `${user.driverSurname} ${user.driverName} ${user.driverPatronymic}`

  /** Госномер машины */
  const licensePlate = user.carNumber

  /** Путь к аватарке */
  /** TODO
   * add API call to get profile image url
   */
  const imageUrl = user.authenticated === 'true' ? undefined : undefined

  /** Количество заказов */
  /** TODO
   * add API call for unfinished orders
   */
  const [openOrdersCount, setOpenOrdersCount] = useState(0)

  /**
   * Состояние смены: true =  открыта, false = закрыта
   */
  const [shiftTogglerDisabled, setShiftTogglerDisabled] = useState(true)
  const [shiftOpened, setShiftOpened] = useState(false)

  useEffect(() => {
    const loadOpenOrdersCount = async () => {
      const uncompletedTrips = await Axios.get<{ count: number }>(
        '/api/v1/trips/uncompleted'
      )

      setOpenOrdersCount(uncompletedTrips.data.count)
    }

    loadOpenOrdersCount()
  }, [openOrdersCount])

  useEffect(() => {
    const loadShift = async () => {
      try {
        const response = await Axios.get<any>('/api/v1/drivers-shifts/open')

        if (response.data.id) {
          setShiftOpened(true)
        } else {
          setShiftOpened(false)
        }
      } catch {
        setShiftOpened(false)
      } finally {
        setShiftTogglerDisabled(false)
      }
    }

    loadShift()
  }, [shiftTogglerDisabled, shiftOpened])

  const toggleShit = async () => {
    try {
      setShiftTogglerDisabled(true)

      if (!shiftOpened) {
        await Axios.post<{ data: string }>('/api/v1/drivers-shifts', {
          openDateTime: new Date(),
        })

        setShiftOpened(true)

        return
      }

      await Axios.put<{ data: string }>('/api/v1/drivers-shifts', {
        closeDateTime: new Date(),
      })

      setShiftOpened(false)
    } finally {
      setShiftTogglerDisabled(false)
    }
  }

  return (
    // кастомный стиль нужен для высоты ряда, иначе всё схлопывается по марджинам
    <>
      <ProfileHeader
        imageUrl={imageUrl}
        fullName={fullName}
        licensePlate={licensePlate}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            {/*Шапка профиля*/}
            {/*Кнопка открыть/закрыть смену*/}
            <Button
              text={shiftOpened ? 'Закрыть смену' : 'Открыть смену'}
              classList={`btn btn-block btn-lg ${
                shiftOpened ? 'btn-danger' : 'btn-success'
              } mt-5 mb-4`}
              name={'shiftToggle'}
              breakpoint={breakpoint}
              column={column}
              disabled={shiftTogglerDisabled}
              action={toggleShit}
            />

            <div className={'row'}>
              <div className={'mx-auto h5 font-weight-bold mb-3'}>
                Новый заказ
              </div>
            </div>

            <LinkBtn
              text={'Поставка'}
              classList="btn btn-block btn-lg btn-primary mb-3"
              breakpoint={breakpoint}
              column={column}
              href={'/add-supply'}
            />

            <LinkBtn
              text={'Перевозка'}
              classList="btn btn-block btn-lg btn-primary mb-3"
              breakpoint={breakpoint}
              column={column}
              href={'/add-shipment'}
            />

            <LinkBtn
              text={'Аренда'}
              classList="btn btn-block btn-lg btn-primary mb-5"
              breakpoint={breakpoint}
              column={column}
              href={'/add-rent'}
            />

            {/* Счётчик открытых заказов*/}
            <div className={'mb-2'}>
              {openOrdersCount === undefined ? null : (
                <CountBadge openOrdersCount={openOrdersCount} />
              )}
            </div>

            <LinkBtn
              text={'История заказов'}
              classList="btn btn-block btn-outline-secondary mb-2"
              breakpoint={breakpoint}
              column={column}
              href="/history"
            />

            <LinkBtn
              text={'Расчётные листы'}
              classList="btn btn-block btn-outline-secondary mb-2"
              breakpoint={breakpoint}
              column={column}
              href="/payroll"
            />

            <Button
              text={'Выйти'}
              classList={'btn btn-block btn-outline-danger'}
              name={'exit'}
              breakpoint={breakpoint}
              column={column}
              action={() => {
                localStorage.removeItem('tokenJwt')
                localStorage.removeItem('authenticated')
                localStorage.removeItem('refreshTokenId')

                window.location.href = '/'
              }}
            />
            {/* column wrapper end*/}
          </div>
          {/* row wrapper end*/}
        </div>
      </div>
    </>
  )
}

export default Main
