export const toNumber = (value: string) => {
  value = value.replace(/[^0-9.,]+/, '')

  if (value.indexOf(',') > value.indexOf('.')) {
    const values = value.split(',')

    if (values.length > 1) {
      return (
        values[0].replace('.', '').replace(',', '') +
        ',' +
        values[1].replace('.', '').replace(',', '')
      )
    }
  } else {
    const values = value.split('.')

    if (values.length > 1) {
      return (
        values[0].replace('.', '').replace(',', '') +
        '.' +
        values[1].replace(',', '').replace('.', '')
      )
    }
  }

  return value
}
