type StandardHeaderProps = {
  /** класс Bootstrap иконки */
  iconClass: string
  /** заголовок меню */
  title: string
}

/**
 * Компонент стандартного заголовка.
 *
 * кастомные стили использованы для:
 * -- контроля размера иконки
 * -- фиксированной высоты навбара
 *
 * bi bi-journal-arrow-down - стрелка вниз для ввода данных
 * bi bi-journal-arrow-up - стрелка вниз для вывода данных
 * bi bi-journal-text - просмотр введённых данных
 * bi bi-list-check - список с галочками для  истории заказов
 *
 */
const StandardHeader = ({ iconClass, title }: StandardHeaderProps) => {
  return (
    <div className="navbar sticky-top shadow bg-white">
      <div className="container-fluid">
        <div className="navbar-brand ml-md-5 pl-md-5">
          <span>
            <i className={`${iconClass}`} />
          </span>
          <span className="h3 navbar-text ml-3">{title}</span>
        </div>
      </div>
    </div>
  )
}

export default StandardHeader
