import React from 'react'

type CountBadgeProps = {
  /** Количество открытых заказов */
  openOrdersCount: number
}

/**TODO
 *  что делать, когда незавершённых заказов нет?
 * @param openOrdersCount
 * @constructor
 */
const CountBadge = ({ openOrdersCount }: CountBadgeProps) => {
  return (
    <div className={'row'}>
      <div className={'badge badge-pill badge-warning mx-auto'}>
        Не завершено заказов: {openOrdersCount}
      </div>
    </div>
  )
}

export default CountBadge
