import React from 'react'
import { BrowserRouter, Switch } from 'react-router-dom'
import Login from './Pages/Login/Login'
import Main from './Pages/Main/Main'
import ProtectedRoute from './Api/react-router-dom/protectedRoute'
import AnonymousRoute from './Api/react-router-dom/anonymousRoute'
import { AddSupply } from './Pages/supply/addSupply'
import { registerLocale } from 'react-datepicker'
import { ru } from 'date-fns/locale'
import { EditSupply } from './Pages/supply/editSupply'
import { AddRent } from './Pages/rent/addRent'
import { EditRent } from './Pages/rent/editRent'
import { AddShipment } from './Pages/shipment/addShipment'
import { EditShipment } from './Pages/shipment/editShipment'
import { History } from './Pages/history/history'
import { Payroll } from './Pages/payroll/payroll'
import { ToastContainer } from 'react-toastify'
import { EditRentHistory } from './Pages/rent/editRentHistory'
import { EditSupplyHistory } from './Pages/supply/editSupplyHistory'
import { EditShipmentHistory } from './Pages/shipment/editShipmentHistory'

function App() {
  registerLocale('ru', ru)

  return (
    <>
      <ToastContainer />
      <BrowserRouter>
        <Switch>
          <AnonymousRoute title="Вход" exact path="/">
            <Login />
          </AnonymousRoute>
          <ProtectedRoute title="Главная" path="/main">
            <Main breakpoint={'md'} column={12} />
          </ProtectedRoute>

          <ProtectedRoute title="Поставка" path="/add-supply">
            <AddSupply />
          </ProtectedRoute>
          <ProtectedRoute title="Поставка" path="/edit-supply/:tripId">
            <EditSupply />
          </ProtectedRoute>
          <ProtectedRoute title="Поставка" path="/edit-supply-history/:tripId">
            <EditSupplyHistory />
          </ProtectedRoute>

          <ProtectedRoute title="Перевозка" path="/add-shipment">
            <AddShipment />
          </ProtectedRoute>
          <ProtectedRoute title="Перевозка" path="/edit-shipment/:tripId">
            <EditShipment />
          </ProtectedRoute>
          <ProtectedRoute
            title="Перевозка"
            path="/edit-shipment-history/:tripId"
          >
            <EditShipmentHistory />
          </ProtectedRoute>

          <ProtectedRoute title="Аренда" path="/add-rent">
            <AddRent />
          </ProtectedRoute>
          <ProtectedRoute title="Аренда" path="/edit-rent/:tripId">
            <EditRent />
          </ProtectedRoute>
          <ProtectedRoute title="Аренда" path="/edit-rent-history/:tripId">
            <EditRentHistory />
          </ProtectedRoute>

          <ProtectedRoute title="История заказов" path="/history">
            <History />
          </ProtectedRoute>

          <ProtectedRoute title="Расчётные листы" path="/payroll">
            <Payroll />
          </ProtectedRoute>
        </Switch>
      </BrowserRouter>
    </>
  )
}

export default App
