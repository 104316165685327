export const ButtonDatePicker = ({ value, onClick }: any) => {
  return (
    <button
      type="button"
      className="btn btn-outline-secondary btn-block text-left"
      onClick={onClick}
    >
      {value || 'Выбрать дату и время'}
    </button>
  )
}
