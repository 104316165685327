import React, { useEffect, useState } from 'react'
import StandardHeader from '../../Molecules/StandardHeader/StandardHeader'
import DatePicker from 'react-datepicker'
import Axios from 'axios'
import { format } from 'date-fns'
import { Link } from 'react-router-dom'
import { ButtonDatePicker } from '../../Api/document/buttonDatePicker'
import NumberFormat from 'react-number-format'
import { toDateFromUtc } from '../../toDateFromUtc'

export const History = () => {
  const [from, setFrom] = useState<Date>()
  const [to, setTo] = useState<Date>()
  const [page, setPage] = useState(1)
  const [onlyNonCompleted, setOnlyNonCompleted] = useState(true)
  const [trips, setTrips] = useState<
    {
      id: string
      type: number
      dateTimeFrom: string
      completed: boolean

      contractorNameFrom: string
      contractorObjectNameFrom: string
      contractorObjectAddressFrom: string

      contractorNameTo: string
      contractorObjectNameTo: string
      contractorObjectAddressTo: string
      payment: number
    }[]
  >()
  const [pages, setPages] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [reload, setReload] = useState(false)

  const removeTrip = async (id: string) => {
    const confirmed = window.confirm('Удалить поездку?')

    if (confirmed) {
      await Axios.delete(`/api/v1/trips/${id}`)

      setReload(!reload)
    }
  }

  useEffect(() => {
    const loadTrips = async () => {
      setIsLoading(true)

      try {
        const trips = await Axios.get<{
          items: {
            id: string
            type: number
            dateTimeFrom: string
            completed: boolean

            contractorNameFrom: string
            contractorObjectNameFrom: string
            contractorObjectAddressFrom: string

            contractorNameTo: string
            contractorObjectNameTo: string
            contractorObjectAddressTo: string
            payment: number
          }[]
          pages: number
        }>(
          `/api/v1/trips?page=${page}&onlyNonCompleted=${onlyNonCompleted}&from=${
            from?.toISOString() || ''
          }&to=${to?.toISOString() || ''}`
        )

        setTrips(trips.data.items)
        setPages(trips.data.pages)
      } finally {
        setIsLoading(false)
      }
    }

    loadTrips()
  }, [page, onlyNonCompleted, from, to, reload])

  return (
    <>
      <StandardHeader iconClass="fas fa-list" title="История заказов" />

      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col">
            <div className="form-group">
              <DatePicker
                locale="ru"
                className="form-control"
                selected={from}
                onChange={(date: Date) => setFrom(date)}
                dateFormat="dd.MM.yyyy"
                isClearable
                placeholderText="С"
                popperPlacement="bottom"
                customInput={<ButtonDatePicker />}
              />
            </div>
            <div className="form-group">
              <DatePicker
                locale="ru"
                className="form-control"
                selected={to}
                onChange={(date: Date) => setTo(date)}
                dateFormat="dd.MM.yyyy"
                isClearable
                placeholderText="По"
                popperPlacement="bottom"
                customInput={<ButtonDatePicker />}
              />
            </div>
            <div className="form-group form-check mt-3">
              <input
                className="form-check-input"
                type="checkbox"
                checked={onlyNonCompleted}
                onChange={(_) => {
                  setOnlyNonCompleted(!onlyNonCompleted)
                }}
                id="onlyNonCompleted"
              />
              <label className="form-check-label" htmlFor="onlyNonCompleted">
                Только незавершённые
              </label>
            </div>
          </div>
        </div>

        {trips?.map((_) => (
          <>
            <hr />
            <div className="row">
              <div className="col">
                {format(toDateFromUtc(_.dateTimeFrom), 'dd.MM.yyyy HH:mm')}
              </div>
              <div className="col">
                {_.completed ? (
                  <span className="badge rounded-pill bg-success btn-block text-white">
                    Завершён
                  </span>
                ) : (
                  <span className="badge rounded-pill bg-warning btn-block">
                    Не завершён
                  </span>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col">
                <strong>
                  {_.type === 0 ? (
                    <Link to={`/edit-supply/${_.id}?backurl=history`}>
                      Поставка
                    </Link>
                  ) : _.type === 1 ? (
                    <Link to={`/edit-shipment/${_.id}?backurl=history`}>
                      Перевозка
                    </Link>
                  ) : (
                    <Link to={`/edit-rent/${_.id}?backurl=history`}>
                      Аренда
                    </Link>
                  )}
                </strong>
              </div>
              <div className="col text-center">
                <strong>
                  <NumberFormat
                    value={_.payment}
                    displayType={'text'}
                    thousandSeparator=" "
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale={true}
                    suffix=" ₽"
                  />
                </strong>
              </div>
            </div>
            <div className="row">
              <div className="col">
                {_.contractorObjectNameFrom}
                <br />
                <span className="text-muted">{_.contractorNameFrom}</span>
                <div hidden={_.type === 2}>
                  <i className="fas fa-arrow-right"></i>
                  <br />
                  {_.contractorObjectNameTo}
                  <br />
                  <span className="text-muted">{_.contractorNameTo}</span>
                </div>
              </div>
            </div>
            {new Date().getDate() > 10 &&
            toDateFromUtc(_.dateTimeFrom).getMonth() < new Date().getMonth() &&
            toDateFromUtc(_.dateTimeFrom).getFullYear() <=
              new Date().getFullYear() ? (
              <></>
            ) : (
              <>
                <div className="row mb-1">
                  <div className="col">
                    {_.type === 0 ? (
                      <Link
                        to={`/edit-supply-history/${_.id}?backurl=history`}
                        className="btn btn-warning"
                      >
                        Изменить
                      </Link>
                    ) : _.type === 1 ? (
                      <Link
                        to={`/edit-shipment-history/${_.id}?backurl=history`}
                        className="btn btn-warning"
                      >
                        Изменить
                      </Link>
                    ) : (
                      <Link
                        to={`/edit-rent-history/${_.id}?backurl=history`}
                        className="btn btn-warning"
                      >
                        Изменить
                      </Link>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <button
                      className="btn btn-danger"
                      onClick={() => removeTrip(_.id)}
                    >
                      Удалить
                    </button>
                  </div>
                </div>
              </>
            )}
          </>
        ))}

        <hr />

        <div className="row">
          <div className="col">
            {!isLoading && pages > 1 ? (
              <nav aria-label="Навигация">
                <ul className="pagination">
                  {page !== 1 ? (
                    <li className="page-item">
                      <button
                        type="button"
                        className="page-link"
                        onClick={() => setPage(1)}
                      >
                        <span aria-hidden="true">Начало</span>
                      </button>
                    </li>
                  ) : null}

                  {page > 1 ? (
                    <li className="page-item">
                      <button
                        type="button"
                        className="page-link"
                        onClick={() => setPage(page - 1)}
                      >
                        &laquo;
                      </button>
                    </li>
                  ) : null}

                  <li className="page-item active">
                    <span className="page-link">{page}</span>
                  </li>

                  {pages - 2 >= page ? (
                    <li className="page-item">
                      <button
                        type="button"
                        className="page-link"
                        onClick={() => setPage(page + 1)}
                      >
                        {page + 1}
                      </button>
                    </li>
                  ) : null}

                  {pages - 1 === page ? (
                    <li className="page-item">
                      <button
                        type="button"
                        className="page-link"
                        onClick={() => setPage(page + 1)}
                      >
                        {page + 1}
                      </button>
                    </li>
                  ) : null}

                  {pages - 1 > page ? (
                    <li className="page-item">
                      <button
                        type="button"
                        className="page-link"
                        onClick={() => setPage(page + 2)}
                      >
                        {page + 2}
                      </button>
                    </li>
                  ) : null}

                  {pages - 2 > page ? (
                    <li className="page-item">
                      <button
                        type="button"
                        className="page-link"
                        onClick={() => setPage(page + 1)}
                      >
                        &raquo;
                      </button>
                    </li>
                  ) : null}
                  {page < pages - 2 ? (
                    <li className="page-item">
                      <button
                        type="button"
                        className="page-link"
                        onClick={() => setPage(pages)}
                      >
                        <span aria-hidden="true">Конец</span>
                        &raquo;
                      </button>
                    </li>
                  ) : null}
                </ul>
              </nav>
            ) : null}
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="form-group">
              <Link to="/main" className="btn btn-block btn-outline-secondary">
                На главную
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
