import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'

import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'react-datepicker/dist/react-datepicker.css'
import { axiosProgressBarInterceptor } from './Api/axios/axiosProgressBarInterceptor'
import { axiosErrorInterceptor } from './Api/axios/axiosErrorInterceptor'
import { axiosAuthInterceptor } from './Api/axios/axiosAuthInterceptor'
import Axios from 'axios'
import * as serviceWorker from './serviceWorkerRegistration'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

axiosProgressBarInterceptor()
axiosErrorInterceptor()
axiosAuthInterceptor()

Axios.defaults.baseURL = process.env.REACT_APP_BASE_URL

Sentry.init({
  dsn:
    'https://b8ca3adcd3f3474194454d144651c6b8@o321339.ingest.sentry.io/5946182',
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
