import jwtDecode from 'jwt-decode'

type user = {
  authenticated?: string
  carNumber?: string
  driverId?: string
  driverName?: string
  driverPatronymic?: string
  driverPhone?: string
  driverSurname?: string
  tokenId?: string
}

const Auth = () => {
  /** получает  JWT token из  localStorage */
  const jwtToken: string | null = localStorage.getItem('tokenJwt')
  /** дешифрует JWT token и записывает результат в переменную*/
  const jwtDecoded: object =
    typeof jwtToken === 'string' ? jwtDecode(jwtToken) : {}
  const user: user = { ...jwtDecoded }

  return user
}

export default Auth
