import React from "react";

type InputBtnProps = {
    /** Текст внутри кнопки */
    text: string;
    /** классы кнопки из  Bootstrap 4 */
    classList: string;
    /** Bootstrap 4  брейкпоинт (sm, md, lg, xl)*/
    breakpoint: string;
    /** ширина колонки по Bootstrap 4 (от 1 до 12)*/
    column: number;
}

const InputBtn = ({text, classList,  breakpoint, column}: InputBtnProps) => {
    return (
        <div className={`row`}>
            <div className={`col col-${breakpoint}-${column} mx-auto`}>
                <input
                    className={classList}
                    type={'submit'}
                    value={text}
                >
                </input>
            </div>
        </div>
    )
}

export default InputBtn